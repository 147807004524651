import { createStore, Module } from 'vuex-smart-module'
import Auth from '@/store/Auth'
import Call from '@/store/Call'
import Config from '@/store/Config'
import Location from '@/store/Location'
import Office from '@/store/Office'
import Translations from '@/store/Translations'
import WorkShift from '@/store/WorkShift'
import SoundNotificators from '@/store/SoundNotificators'

const rootModule = new Module({
  modules: {
    Auth,
    Call,
    Config,
    Location,
    Translations,
    Office,
    WorkShift,
    SoundNotificators
  }
})

export const store = createStore(
  rootModule,
  {
    strict: process.env.NODE_ENV !== 'production'
  }
)
