
import { Options } from 'vue-class-component'
import MultipleSelect from '@vueform/multiselect'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

@Options({
  props: {
    id: String,
    options: Array,
    searchable: Boolean,
    value: [Number, String, Array],
    onChange: Function,
    multiple: Boolean
  },
  components: {
    MultipleSelect
  }
})

export default class BaseSelect extends TranslatableClassComponent {
  translationGroup = 'squad_base'

  onChange?: (value: string | string[] | null) => void | undefined

  handleChange (value: string | string[] | null) {
    if (typeof this.onChange !== 'undefined') {
      this.onChange(value)
    }
  }
}
