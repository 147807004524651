import { mixins } from 'vue-class-component'
import { TranslationData } from '@/types/api/responses/Error'
import { DefaultResponse } from '@/types/api/responses/DefaultResponse'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

export default class HttpErrorsHandler extends mixins(TranslatableClassComponent, TranslatableSwal) {
  protected httpErrorCodesForSkip: number[] = [401, 424]
  protected fieldsHandlersForValidationHandler: Record<string, (translatedError: string) => void> = {}

  httpErrorHandler (error: DefaultResponse, validationHandlers: Record<string, (translatedError: string) => void> = this.fieldsHandlersForValidationHandler) {
    const errorCode = error.code

    if (this.needSkipHttpError(errorCode)) {
      return
    }

    if (errorCode === 422) {
      this.validationHttpErrorHandler(error, validationHandlers)
      return
    }

    this.unexpectedHttpErrorHandler(error.errors?.message || 'errors.unexpected')
  }

  validationHttpErrorHandler (error: DefaultResponse, validationHandlers: Record<string, (translatedError: string) => void>) {
    Object.entries(error.errors?.details || {}).map(([column, translationsData]) => {
      translationsData.forEach((translationData: TranslationData) => {
        const translatedError = this.translateValidationError(translationData)
        if (typeof validationHandlers[column] !== 'undefined') {
          validationHandlers[column](translatedError)
          return
        }

        this.unexpectedValidationHttpErrorHandler(translatedError)
      })
    })
  }

  needSkipHttpError (errorCode: number): boolean {
    return this.httpErrorCodesForSkip.includes(errorCode) || errorCode >= 500
  }

  unexpectedHttpErrorHandler (errorMessage: string): void {
    this.swal({
      icon: 'error',
      title: this.translateByFullKey(errorMessage)
    })
  }

  unexpectedValidationHttpErrorHandler (translatedError: string): void {
    this.swal({
      icon: 'error',
      title: translatedError
    })
  }
}
