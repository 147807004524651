import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "offline-toaster"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    (!_ctx.isOnline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate('network_error')), 1))
      : _createCommentVNode("", true)
  ], 64))
}