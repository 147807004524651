
import { Options } from 'vue-class-component'
import { Loader } from '@googlemaps/js-api-loader'

import { GMapDirection } from '@/types/libraries/GMapDirection'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

import Card from '@/components/base/cards/Card.vue'

@Options({
  data () {
    return {
      map: undefined,
      mapMarkers: undefined,
      mapPolygons: undefined,
      directionsService: undefined,
      directionsRenderer: undefined
    }
  },
  components: {
    Card
  },
  props: {
    id: String,
    title: String,
    height: String,
    center: {
      lat: Number,
      lng: Number
    },
    direction: {
      from: Object,
      to: Object
    },
    markers: [Object, Array],
    polygons: Array,
    zoom: Number,
    mapTypeId: String,
    options: Object,
    myLocationButton: Boolean
  },
  methods: {
    setPolygons () {
      if (!this.map) {
        return
      }

      if (Array.isArray(this.mapPolygons)) {
        this.mapPolygons.forEach((polygon: google.maps.Polygon) => {
          polygon.setVisible(false)
        })
      }

      this.mapPolygons = []
      if (Array.isArray(this.polygons)) {
        this.polygons.forEach((polygon: google.maps.PolygonOptions) => {
          this.mapPolygons.push(new google.maps.Polygon({ ...polygon, map: this.map }))
        })
      }
    },
    addMyLocationButton () {
      const myLocationButton = document.querySelector('.custom-controls .my-location-control')
      if (!myLocationButton) {
        return
      }

      const myLocationButtonContainer = document.createElement('div')
      myLocationButtonContainer.classList.add('my-location-button-container')
      myLocationButtonContainer.classList.add('custom-button')
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(myLocationButtonContainer)
      myLocationButtonContainer.append(myLocationButton)
    },
    myLocationButtonClickHandler () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
          this.map.setCenter(latlng)
        })
      }
    },
    setDirection () {
      if (!this.directionsService) {
        this.directionsService = new google.maps.DirectionsService()
      }

      if (!this.directionsRenderer) {
        this.directionsRenderer = new google.maps.DirectionsRenderer({
          map: this.map,
          suppressMarkers: true
        })
      }

      this.directionsService
        .route({
          origin: this.direction.from,
          destination: this.direction.to,
          travelMode: google.maps.TravelMode.DRIVING
        })
        .then((directionResult: google.maps.DirectionsResult) => {
          this.directionsRenderer.setDirections(directionResult)
        })
    },
    clearDirection () {
      if (!this.directionsService) {
        this.directionsService = new google.maps.DirectionsService()
      }

      this.directionsRenderer.setMap(null)
      this.directionsRenderer = undefined
    }
  },
  created () {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
      version: 'weekly'
    })

    loader.load().then(() => {
      const mapNode = document.getElementById(this.id)
      if (!mapNode) {
        return
      }

      this.map = new google.maps.Map(mapNode, {
        center: this.center,
        zoom: this.zoom || 10
      })

      this.setMarkers()
      this.setPolygons()

      if (this.direction) {
        this.setDirection()
      }

      if (this.myLocationButton) {
        this.addMyLocationButton()
      }
    })
  },
  watch: {
    markers () {
      this.setMarkers()
    },
    polygons () {
      this.setPolygons()
    },
    direction (direction?: GMapDirection, prevDirection?: GMapDirection) {
      if (!direction && prevDirection) {
        this.clearDirection()
      }

      if (direction && !prevDirection) {
        this.setDirection()
      }
    }
  }
})

export default class Map extends TranslatableClassComponent {
  translationGroup = 'squad_base'

  markers?: Record<string, object> | object[] | undefined
  mapMarkers?: Record<string, google.maps.Marker> | google.maps.Marker[] | undefined
  map!: google.maps.Map | google.maps.StreetViewPanorama | null;

  setMarkers () {
    if (!this.map) {
      return
    }

    if (!this.markers || (!this.markers.length && !Object.keys(this.markers).length)) {
      Object.values(this.mapMarkers || {}).forEach((marker: google.maps.Marker) => {
        marker.setVisible(false)
      })
      this.mapMarkers = undefined

      return
    }

    if (!this.mapMarkers || (!this.mapMarkers.length && !Object.keys(this.mapMarkers).length)) {
      if (Array.isArray(this.markers)) {
        this.mapMarkers = []
        this.markers.forEach((marker) => {
          if (!Array.isArray(this.mapMarkers)) {
            return
          }

          this.mapMarkers.push(new google.maps.Marker({ ...marker, map: this.map }))
        })

        return
      }

      this.mapMarkers = {}
      Object.entries(this.markers).forEach(([markerKey, marker]) => {
        if (typeof this.mapMarkers !== 'object' || Array.isArray(this.mapMarkers)) {
          return
        }

        this.mapMarkers[markerKey] = new google.maps.Marker({ ...marker, map: this.map })
      })

      return
    }

    if (Array.isArray(this.markers)) {
      Object.values(this.mapMarkers).forEach((marker: google.maps.Marker) => {
        marker.setVisible(false)
      })

      this.mapMarkers = []

      this.markers.forEach((marker) => {
        if (!Array.isArray(this.mapMarkers)) {
          return
        }

        this.mapMarkers.push(new google.maps.Marker({ ...marker, map: this.map }))
      })

      return
    }

    if (Array.isArray(this.mapMarkers)) {
      this.mapMarkers.forEach((mapMarkers: google.maps.Marker) => {
        mapMarkers.setVisible(false)
      })

      this.mapMarkers = {}
      Object.entries(this.markers).forEach(([markerKey, marker]) => {
        if (typeof this.mapMarkers !== 'object' || Array.isArray(this.mapMarkers)) {
          return
        }

        this.mapMarkers[markerKey] = new google.maps.Marker({ ...marker, map: this.map })
      })

      return
    }

    const mapMarkersNames = Object.keys(this.markers)
    Object.entries(this.mapMarkers).forEach(([markerKey, marker]) => {
      if (!mapMarkersNames.includes(markerKey)) {
        if (typeof this.mapMarkers === 'object') {
          marker.setVisible(false)
          delete this.mapMarkers[markerKey]
        }

        return
      }

      if (!this.markers) {
        return
      }

      Object.entries(this.markers[markerKey]).forEach(([property, value]) => {
        switch (property) {
          case 'position':
            marker.setPosition(value as google.maps.LatLng)
            break
          case 'icon':
            marker.setIcon(value as google.maps.Symbol)
            break
          case 'title':
            marker.setTitle(value as string)
            break
        }
      })
    })

    const oldMapMarkersNames = Object.keys(this.mapMarkers)
    Object.entries(this.markers).forEach(([markerKey, marker]) => {
      if (oldMapMarkersNames.includes(markerKey)) {
        return
      }

      if (typeof this.mapMarkers !== 'object') {
        return
      }

      this.mapMarkers[markerKey] = new google.maps.Marker({ ...marker, map: this.map })
    })
  }
}
