import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createBlock(_component_BaseButton, {
    text: _ctx.text,
    classes: `main-button ${_ctx.classes}`,
    disabled: _ctx.disabled,
    onClick: _ctx.onClick
  }, null, 8, ["text", "classes", "disabled", "onClick"]))
}