
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    value: String,
    onInput: Function,
    onChange: Function,
    onEnterKeyup: Function,
    name: String,
    label: String,
    error: String,
    description: String,
    classes: String,
    placeholder: String,
    prefix: String,
    disabled: Boolean,
    limit: Number
  },
  computed: {
    classesList () {
      return `text-input ${this.classes} ${this.error ? 'error' : ''} ${this.prefix ? 'with-prefix' : ''}`
    },
    id () {
      return `text-input-${this.name}`
    }
  },
  methods: {
    handleInput ($event: {target: {value: string}}) {
      if (this.limit) {
        $event.target.value = $event.target.value.substr(0, this.limit)
      }

      this.onInput($event)
    }
  }
})

export default class TextField extends Vue {}
