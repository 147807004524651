
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import router from '@/router'

import soundNotificator from '@/libraries/soundNotificator'
import observer from '@/libraries/observer'
import { CALL_REPORTS_OBSERVER_NAME } from '@/constants'

import { CallType } from '@/types/entities/CallType'
import { ReportScheduleItem } from '@/types/libraries/ReportScheduleItem'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import CallReportModal from '@/components/custom/modals/CallReportModal.vue'
import CallConfirmationModal from '@/components/custom/modals/CallConfirmationModal.vue'

import WorkShift from '@/store/WorkShift'
import CallStorage from '@/store/Call'

@Options({
  components: {
    CallReportModal,
    CallConfirmationModal
  },
  data () {
    return {
      reportModal: false,
      currentReportCall: undefined
    }
  },
  methods: {
    ...createMapper(WorkShift).mapActions({
      setCallsReportsSchedule: 'setCallsReportsSchedule'
    }),
    ...createMapper(CallStorage).mapMutations({
      setIsActiveUnconfirmed: 'setIsActiveUnconfirmed'
    }),
    showReportModalIfNeeded (time: number) {
      if (this.reportModal || !this.workShift || (!this.workShift.not_reported_calls.length && !this.workShift.not_approved_report_calls)) {
        return
      }

      let callId: number | undefined
      let withoutSound: boolean = false
      if (this.callsReportsSchedule) {
        const callsReportsScheduleEntries: [string, ReportScheduleItem][] = Object.entries(this.callsReportsSchedule)
        const pendingCallsIds = this.workShift.not_approved_report_calls.map((call: CallType) => {
          return call.id
        })

        let callEntry = callsReportsScheduleEntries.find(([callId, reportScheduleItem]) => {
          return reportScheduleItem.nextShowTime <= time && pendingCallsIds.includes(Number(callId)) && reportScheduleItem.needEdit && (!this.workShift.active_call || reportScheduleItem.ignoreActiveCall)
        })

        if (!callEntry) {
          const callsIds = this.workShift.not_reported_calls.map((call: CallType) => {
            return call.id
          })

          callEntry = callsReportsScheduleEntries.find(([callId, reportScheduleItem]) => {
            return reportScheduleItem.nextShowTime <= time && callsIds.includes(Number(callId)) && !callsIds.needEdit && (!this.workShift.active_call || reportScheduleItem.ignoreActiveCall)
          })
        }

        if (callEntry) {
          callId = Number(callEntry[0])
          withoutSound = Boolean(callEntry[1].withoutSound)
          const callsReportsSchedule = { ...this.callsReportsSchedule }
          callsReportsSchedule[callId] = {
            withoutSound: false,
            nextShowTime: 0,
            needEdit: false,
            ignoreActiveCall: false
          }
          this.setCallsReportsSchedule(callsReportsSchedule)
        }
      }

      if (!callId && this.workShift.active_call) {
        return
      }

      this.currentReportCall = callId
        ? this.workShift.not_approved_report_calls.concat(this.workShift.not_reported_calls).find((call: CallType) => call.id === callId)
        : this.workShift.not_reported_calls.find((call: CallType) => !Object.keys(this.callsReportsSchedule || {}).includes(String(call.id)))

      if (!this.currentReportCall) {
        return
      }

      this.reportModal = true

      if (!withoutSound) {
        soundNotificator.notify()
      }
    },
    handleModalClose () {
      this.currentReportCall = undefined
      this.reportModal = false
    },
    resetUnconfirmedFlag () {
      this.setIsActiveUnconfirmed(false)
    }
  },
  computed: {
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift',
      callsReportsSchedule: 'callsReportsSchedule'
    }),
    ...createMapper(CallStorage).mapGetters({
      active: 'active',
      isActiveUnconfirmed: 'isActiveUnconfirmed'
    }),
    timeRange () {
      return `📅 ${new Date(this.currentReportCall.created_at.replaceAll('-', '/')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} — ${new Date(this.currentReportCall.finished_at.replaceAll('-', '/')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    }
  },
  watch: {
    isActiveUnconfirmed (currentState: boolean, previousState: boolean) {
      if (currentState) {
        soundNotificator.call(true)
      } else {
        soundNotificator.stopCall()

        if (currentState !== previousState && this.$route.name !== 'Main') {
          router.push('/')
        }
      }
    },
    active (activeCall: CallType | undefined, previousActiveCall: CallType | undefined) {
      if (
        this.isActiveUnconfirmed &&
        (
          !activeCall ||
          !['squad_in_road', 'pending'].includes(activeCall.status)
        )
      ) {
        this.resetUnconfirmedFlag()
      }
    }
  },
  created () {
    observer.subscribe(this.showReportModalIfNeeded, CALL_REPORTS_OBSERVER_NAME)
  }
})

export default class CallProvider extends mixins(TranslatableClassComponent, TranslatableSwal) {
  translationGroup = 'squad_base'
}
