import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-photo" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "user-details" }
const _hoisted_4 = { class: "user-name" }
const _hoisted_5 = { class: "user-phone" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "client-address"
}
const _hoisted_8 = {
  key: 1,
  class: "client-address"
}
const _hoisted_9 = {
  key: 2,
  class: "user-trusted-phone"
}
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { classes: "user-card" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.image,
          alt: _ctx.name
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            href: `tel:${_ctx.phone}`
          }, " 📞 " + _toDisplayString(_ctx.phone), 9, _hoisted_6)
        ]),
        (_ctx.address)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 📪 " + _toDisplayString(_ctx.address), 1))
          : _createCommentVNode("", true),
        (_ctx.area)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, " 🚨 " + _toDisplayString(_ctx.area), 1))
          : _createCommentVNode("", true),
        (_ctx.trustedPhone)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("a", {
                href: `tel:${_ctx.trustedPhone}`
              }, " ☎️ " + _toDisplayString(_ctx.trustedPhone), 9, _hoisted_10)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}