import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import soundNotificator from '@/libraries/soundNotificator'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

export default class TranslatableSwal extends TranslatableClassComponent {
  protected componentSwalConfig: SweetAlertOptions = {}

  swal (options: SweetAlertOptions): Promise<SweetAlertResult> {
    const swalOptions: Record<string, any> = {
      confirmButtonText: this.translate('confirm', 'squad_base'),
      cancelButtonText: this.translate('cancel', 'squad_base'),
      denyButtonText: this.translate('deny', 'squad_base')
    }

    Object.entries(this.componentSwalConfig).forEach(([swalOptionKey, swalOptionValue]) => {
      swalOptions[swalOptionKey] = swalOptionValue
    })

    Object.entries(options).forEach(([swalOptionKey, swalOptionValue]) => {
      swalOptions[swalOptionKey] = swalOptionValue
    })

    return new Promise((resolve) => {
      Swal.fire(swalOptions).then(result => resolve(result))
    })
  }

  notifyAlert (title: string, text: string = '', icon: 'success' | 'error' | 'warning' | 'info' | 'question' | undefined = undefined) {
    this.swal({ title, text, icon })
    soundNotificator.notify()
  }
}
