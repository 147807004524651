import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!

  return (_openBlock(), _createBlock(_component_TextField, {
    value: _ctx.value,
    error: _ctx.error,
    description: _ctx.description,
    label: _ctx.label,
    prefix: _ctx.prefix,
    onInput: _ctx.handleInput,
    onChange: _ctx.onChange,
    onEnterKeyup: _ctx.onEnterKeyup,
    limit: _ctx.limit,
    disabled: _ctx.disabled
  }, null, 8, ["value", "error", "description", "label", "prefix", "onInput", "onChange", "onEnterKeyup", "limit", "disabled"]))
}