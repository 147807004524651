import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import { Store } from 'vuex'

class SoundNotificatorsState {
  needLoadSoundNotificatorsPermission: boolean = false;
}

class SoundNotificatorsGetters extends Getters<SoundNotificatorsState> {
  get needLoadSoundNotificatorsPermission () {
    return this.state.needLoadSoundNotificatorsPermission
  }
}

class SoundNotificatorsMutations extends Mutations<SoundNotificatorsState> {
  setNeedLoadSoundNotificatorsPermission (needLoadSoundNotificatorsPermission: boolean) {
    this.state.needLoadSoundNotificatorsPermission = needLoadSoundNotificatorsPermission
  }
}

class SoundNotificatorsActions extends Actions<
  SoundNotificatorsState,
  SoundNotificatorsGetters,
  SoundNotificatorsMutations,
  SoundNotificatorsActions
  > {
  protected store!: Store<any>

  $init (store: Store<any>) {
    super.$init(store)
    this.store = store
  }
}

export default new Module({
  state: SoundNotificatorsState,
  getters: SoundNotificatorsGetters,
  mutations: SoundNotificatorsMutations,
  actions: SoundNotificatorsActions
})
