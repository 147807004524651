import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "authorization" }
const _hoisted_2 = { class: "login-form" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "auth-code-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneInput = _resolveComponent("PhoneInput")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.translate('auth')), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PhoneInput, {
            phone: _ctx.phone,
            error: _ctx.phoneError,
            label: _ctx.translate('phone'),
            onInput: _ctx.handlePhoneChange,
            disabled: !!_ctx.timeLeft,
            onEnterKeyup: _ctx.auth
          }, null, 8, ["phone", "error", "label", "onInput", "disabled", "onEnterKeyup"]),
          (_ctx.codeExpired)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.translate('code_expired')), 1))
            : _createCommentVNode("", true),
          (!_ctx.timeLeft)
            ? (_openBlock(), _createBlock(_component_MainButton, {
                key: 1,
                text: _ctx.translate('submit', 'squad_base'),
                classes: "auth-submit",
                onClick: _ctx.auth,
                disabled: _ctx.phone.length !== 12
              }, null, 8, ["text", "onClick", "disabled"]))
            : _createCommentVNode("", true),
          (!!_ctx.timeLeft)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_NumberInput, {
                  value: _ctx.code,
                  error: _ctx.codeError,
                  limit: _ctx.smsCodeLength,
                  label: _ctx.translate('code'),
                  onInput: _ctx.handleAuthCodeInput,
                  onChange: _ctx.handleAuthCodeChange,
                  onEnterKeyup: _ctx.sendCode,
                  description: _ctx.translateWithVariables('code_time_left', {time: _ctx.timer})
                }, null, 8, ["value", "error", "limit", "label", "onInput", "onChange", "onEnterKeyup", "description"]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_BaseButton, {
                    text: _ctx.translate('change_phone'),
                    classes: "change-phone",
                    onClick: _ctx.clearCodeTimer
                  }, null, 8, ["text", "onClick"]),
                  _createVNode(_component_MainButton, {
                    text: _ctx.translate('send_code'),
                    classes: "auth-code-send",
                    onClick: _ctx.sendCode,
                    disabled: _ctx.code.length !== _ctx.smsCodeLength
                  }, null, 8, ["text", "onClick", "disabled"])
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}