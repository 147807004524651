import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultipleSelect = _resolveComponent("MultipleSelect")!

  return (_openBlock(), _createBlock(_component_MultipleSelect, {
    class: "base-select",
    id: _ctx.id,
    options: _ctx.options,
    searchable: _ctx.searchable,
    modelValue: _ctx.value,
    mode: _ctx.multiple ? 'tags' : 'single',
    onChange: _ctx.handleChange,
    noResultsText: _ctx.translate('no_result_found')
  }, null, 8, ["id", "options", "searchable", "modelValue", "mode", "onChange", "noResultsText"]))
}