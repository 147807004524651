import Swal from 'sweetalert2'

import router from '@/router'
import configuredAxios from '@/libraries/axiosClient'

import { CUSTOM_AXIOS_ERROR } from '@/constants'

import { DefaultResponse } from '@/types/api/responses/DefaultResponse'

import { store } from '@/store'

export function interceptorForAxiosClient () {
  configuredAxios.interceptors.request.use(
    (request) => {
      const isOnline = window.navigator.onLine
      store.commit('Config/setIsOnline', isOnline)

      if (!isOnline) {
        throw new configuredAxios.Cancel(CUSTOM_AXIOS_ERROR)
      }

      return request
    }
  )
  configuredAxios.interceptors.response.use(
    (response) => response,
    (error: {message: string, response: {data: DefaultResponse}}) => {
      if (error.message === CUSTOM_AXIOS_ERROR) {
        return
      }

      const fullKey = error.response.data.errors?.message || 'errors.unexpected'
      const separatedTranslationKeyParts = fullKey.split('.')
      if (separatedTranslationKeyParts.length < 2) {
        return fullKey
      }

      const [translationGroup, ...translationKeyParts] = separatedTranslationKeyParts
      const translatedError = store.getters['Translations/getTranslation'](translationGroup, translationKeyParts.join('.'))

      switch (error.response.data.code) {
        case 401:
          store.commit('Auth/logout')
          router.push('/auth')
          break
        case 406:
          store.commit('WorkShift/remove')
          break
        case 424:
          Swal.fire({
            icon: 'error',
            title: translatedError
          })
          break
      }

      if (error.response.data.code >= 500) {
        Swal.fire({
          icon: 'error',
          title: store.getters.getTranslation('errors', 'unexpected')
        })
      }

      return Promise.reject(error.response.data)
    }
  )
}
