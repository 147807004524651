
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    classes: String,
    color: String,
    background: String
  },
  computed: {
    style () {
      const style: Partial<CSSStyleDeclaration> = {}

      if (this.color) {
        style.color = this.color
      }

      if (this.background) {
        style.background = this.background
      }

      return style
    }
  }
})

export default class Card extends Vue {}
