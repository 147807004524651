
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import ArrayHelper from '@/libraries/ArrayHelper'

import { ExtendingRequestType, PauseType, TimeChangeLogElementType } from '@/types/entities/PauseType'
import { WorkShiftType } from '@/types/entities/WorkShiftType'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import EmptyButton from '@/components/base/buttons/EmptyButton.vue'
import PauseModal from '@/components/layouts/PauseModal.vue'

import Config from '@/store/Config'
import WorkShift from '@/store/WorkShift'

@Options({
  data () {
    return {
      pauseModal: false,
      extensionPauseModal: false,
      pauseReason: '',
      pauseTimeRange: 5
    }
  },
  components: {
    EmptyButton,
    PauseModal
  },
  computed: {
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift'
    }),
    pauseReasons (): string[] {
      return this.getAllGroupTranslations('squad_pause_reasons')
    },
    hasPendingExtendingPauseRequest () {
      return this.workShift.last_pause?.extensions.some((extendingRequest: ExtendingRequestType) => {
        return extendingRequest.status === 'pending'
      })
    }
  },
  methods: {
    ...createMapper(WorkShift).mapActions({
      pause: 'pause',
      extensionPause: 'extensionPause',
      resume: 'resume'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    handlePauseClick (time: Number, reason: String) {
      this.setFetching(true)
      this.pause({ reason, time }).then(() => {
        this.pauseModal = false
        this.swal({
          icon: 'success',
          text: this.translate('pause_created')
        })
        this.setFetching(false)
      }).catch(() => {
        this.setFetching(false)
      })
    },
    handleExtensionPauseClick (time: Number, reason: String) {
      this.setFetching(true)
      this.extensionPause({ reason, time }).then(() => {
        this.extensionPauseModal = false
        this.swal({
          icon: 'success',
          text: this.translate('extension_pause_created')
        })
        this.setFetching(false)
      }).catch(() => {
        this.setFetching(false)
      })
    },
    handleResumeClick () {
      this.setFetching(true)
      this.resume().then(() => {
        this.swal({
          icon: 'success',
          text: this.translate('pause_finished')
        })
        this.setFetching(false)
      }).catch(() => {
        this.setFetching(false)
      })
    },
    showPauseNotificationIfNeeded (pause?: PauseType, prevPause?: PauseType) {
      const pauseStatus = pause?.status

      if (
        !pause ||
        !pauseStatus ||
        !pause.details ||
        !['active', 'rejected'].includes(pauseStatus) ||
        (pauseStatus === 'rejected' && prevPause?.status === 'rejected' && prevPause?.id === pause.id)
      ) {
        return
      }

      if (pause.id !== prevPause?.id) {
        const pauseLog = Object.values(ArrayHelper.toObject(
          [
            ...(pause?.extensions || []).map((extendingRequest: ExtendingRequestType) => {
              return {
                timestamp: extendingRequest.timestamp,
                text: this.translateWithVariables(
                  extendingRequest.status === 'rejected' ? 'added_and_rejected_pause_extending' : 'added_pause_extending',
                  { reason: extendingRequest.reason || '', duration: extendingRequest.time / 60 }
                )
              }
            }),
            ...(pause.details.time_change_log || []).map((timeChangeLogElement: TimeChangeLogElementType) => {
              return {
                timestamp: timeChangeLogElement.timestamp,
                text: this.getTimeChangeMessage(timeChangeLogElement)
              }
            })
          ],
          'timestamp'
        )).map((pauseLogElement) => `<span>${pauseLogElement.text}</span>`)

        this.notifyAlert(this.translate('pause_changes'), pauseLog.join('<br>'), 'info')

        return
      }

      if (pauseStatus !== prevPause?.status && !pause.extensions?.length && !pause.details.time_change_log?.length) {
        const isPauseActive = pauseStatus === 'active'
        this.notifyAlert(
          this.translate(isPauseActive ? 'pause_accepted' : 'pause_rejected'),
          pause.details?.admin_comment,
          isPauseActive ? 'success' : 'error'
        )

        return
      }

      const rejectedRequestsForExtending = pause.extensions?.filter((extendingRequest: ExtendingRequestType, index: number) => {
        const prevExtendingRequest = prevPause.extensions ? prevPause.extensions[index] : undefined
        return !!prevExtendingRequest && prevExtendingRequest.status !== extendingRequest.status && extendingRequest.status === 'rejected'
      })

      const hasNewRejectedRequestsForExtending = Array.isArray(rejectedRequestsForExtending) && rejectedRequestsForExtending.length

      const pauseChanges = pause.details.time_change_log
      const prevPauseChangesLength = prevPause.details?.time_change_log?.length

      const hasNewTimeChanges = Array.isArray(pauseChanges) && pauseChanges.length !== prevPauseChangesLength

      if (!hasNewTimeChanges && !hasNewRejectedRequestsForExtending) {
        return
      }

      if (hasNewTimeChanges && hasNewRejectedRequestsForExtending) {
        const pauseLog = Object.values(ArrayHelper.toObject(
          [
            ...(rejectedRequestsForExtending || []).map((extendingRequest: ExtendingRequestType) => {
              return {
                timestamp: extendingRequest.timestamp,
                text: this.getRejectRequestForExtendingMessage(extendingRequest)
              }
            }),
            ...(pause.details.time_change_log?.slice(prevPauseChangesLength) || []).map((timeChangeLogElement: TimeChangeLogElementType) => {
              return {
                timestamp: timeChangeLogElement.timestamp,
                text: this.getTimeChangeMessage(timeChangeLogElement)
              }
            })
          ],
          'timestamp'
        )).map((pauseLogElement) => `<span>${pauseLogElement.text}</span>`)

        this.notifyAlert(this.translate('pause_changes'), pauseLog.join('<br>'), 'info')

        return
      }

      if (hasNewTimeChanges && pauseChanges) {
        const newTimeChanges = pauseChanges.slice(prevPauseChangesLength)

        if (newTimeChanges.length === 1) {
          this.notifyAlert(
            this.translate(newTimeChanges[0].type === 'request' ? 'change_pause_duration_from_request' : 'change_pause_duration_from_admin'),
            this.translateWithVariables(
              'change_pause_duration_details',
              { reason: newTimeChanges[0].admin_comment || '', duration: (newTimeChanges[0].time / 60).toFixed() }
            ),
            'info'
          )

          return
        }

        const newTimeChangesMessages = newTimeChanges.map((timeChangeLogElement: TimeChangeLogElementType) => {
          return `<span>${this.getTimeChangeMessage(timeChangeLogElement)}</span>`
        })

        this.notifyAlert(this.translate('pause_changes'), newTimeChangesMessages.join('<br>'), 'info')

        return
      }

      if (!rejectedRequestsForExtending) {
        return
      }

      if (rejectedRequestsForExtending.length === 1) {
        this.notifyAlert(
          this.translate('rejected_pause_extending'),
          this.translateWithVariables(
            'change_pause_duration_details',
            { reason: rejectedRequestsForExtending[0].admin_comment || '', duration: (rejectedRequestsForExtending[0].time / 60).toFixed() }
          ),
          'error'
        )

        return
      }

      rejectedRequestsForExtending.map((extendingRequest: ExtendingRequestType) => {
        return `<span>${this.getRejectRequestForExtendingMessage(extendingRequest)}</span>`
      })

      this.notifyAlert(this.translate('pause_changes'), rejectedRequestsForExtending.join('<br>'), 'info')
    },
    getTimeChangeMessage (timeChangeLogElement: TimeChangeLogElementType) {
      return this.translate(timeChangeLogElement.type === 'request' ? 'change_pause_duration_from_request' : 'change_pause_duration_from_admin') +
        ': ' +
        this.translateWithVariables(
          'change_pause_duration_details',
          { reason: timeChangeLogElement.admin_comment || '', duration: (timeChangeLogElement.time / 60).toFixed() }
        )
    },
    getRejectRequestForExtendingMessage (extendingRequest: ExtendingRequestType) {
      return this.translate('rejected_pause_extending') +
        ': ' +
        this.translateWithVariables(
          'change_pause_duration_details',
          { reason: extendingRequest.reason || '', duration: extendingRequest.time / 60 }
        )
    }
  },
  watch: {
    workShift (workShift: WorkShiftType, prevWorkShift: WorkShiftType) {
      if (!workShift) {
        return
      }

      this.showPauseNotificationIfNeeded(workShift?.last_pause, prevWorkShift?.last_pause)
    }
  }
})

export default class PauseControls extends mixins(TranslatableClassComponent, TranslatableSwal) {
  translationGroup = 'squad_base'
}
