
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import EmptyButton from '@/components/base/buttons/EmptyButton.vue'

import Config from '@/store/Config'
import WorkShift from '@/store/WorkShift'

@Options({
  components: {
    EmptyButton
  },
  computed: {
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift'
    }),
    ...createMapper(Config).mapGetters({
      maxDistanceToClientForArriving: 'maxDistanceToClientForArriving',
      showMap: 'showMap',
      showSquad: 'showSquad'
    })
  },
  methods: {
    ...createMapper(Config).mapMutations({
      setShowMap: 'setShowMap',
      setShowSquad: 'setShowSquad'
    }),
    showMapHandler () {
      this.setShowMap(!this.showMap)
    },
    showSquadHandler () {
      this.setShowSquad(!this.showSquad)
    }
  }
})

export default class Header extends mixins(TranslatableClassComponent, TranslatableSwal) {
  translationGroup = 'squad_base'
}
