
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    text: String,
    onClick: Function,
    disabled: Boolean,
    classes: String
  }
})

export default class EmptyButton extends Vue {}
