
import { Options, Vue } from 'vue-class-component'
import BaseButton from '@/components/base/buttons/BaseButton.vue'

@Options({
  components: { BaseButton },
  props: {
    text: String,
    onClick: Function,
    disabled: Boolean,
    classes: String
  }
})

export default class MainButton extends Vue {}
