
import { mixins, Options } from 'vue-class-component'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

import MainButton from '@/components/base/buttons/MainButton.vue'
import Modal from '@/components/base/modal/Modal.vue'

@Options({
  props: {
    onConfirm: Function
  },
  components: {
    MainButton,
    Modal
  },
  methods: {
    confirmCall () {
      this.onConfirm()
    }
  }
})

export default class CallConfirmationModal extends mixins(TranslatableClassComponent) {
  translationGroup = 'squad_base'
}
