
import { Options } from 'vue-class-component'
import TextField from '@/components/base/inputs/TextField.vue'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

@Options({
  props: {
    onInput: Function,
    onChange: Function,
    onEnterKeyup: Function,
    error: String,
    description: String,
    value: String,
    label: String,
    prefix: String,
    limit: Number,
    disabled: Boolean
  },
  components: {
    TextField
  }
})

export default class NumberInput extends TranslatableClassComponent {
  phone!: string;
  localError!: string;
  onInput!: (phone: string) => void;

  translationGroup = 'squad_validators'

  handleInput ($event: {target: {value: string}}) {
    const numbersFromString = $event.target.value.match(/\d/g)
    $event.target.value = (numbersFromString || []).join('')

    this.onInput($event.target.value)
  }
}
