
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    label: String,
    labelFor: String,
    error: String,
    description: String,
    classes: String
  }
})

export default class FormGroup extends Vue {}
