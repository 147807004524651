
import { Options } from 'vue-class-component'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import Card from '@/components/base/cards/Card.vue'
import EmptyButton from '@/components/base/buttons/EmptyButton.vue'

@Options({
  components: {
    EmptyButton,
    Card
  },
  props: {
    title: String,
    onClose: Function,
    classes: String
  },
  methods: {
    escKeyClickEvent (event: KeyboardEvent): void {
      if (event.key === 'Escape') {
        this.onClose()
      }
    }
  },
  mounted () {
    if (this.onClose) {
      document.addEventListener('keydown', this.escKeyClickEvent)
    }
  },
  beforeUnmount () {
    document.removeEventListener('keydown', this.escKeyClickEvent)
  }
})

export default class Modal extends TranslatableClassComponent {}
