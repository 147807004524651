
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    value: String,
    rows: Number,
    onInput: Function,
    onChange: Function,
    onCtrlEnterKeyup: Function,
    name: String,
    label: String,
    error: String,
    description: String,
    classes: String,
    placeholder: String,
    disabled: Boolean,
    resizable: Boolean,
    limit: Number
  },
  computed: {
    classesList () {
      return `textarea ${this.classes || ''} ${this.error ? 'error' : ''} ${this.resizable ? 'resizable' : ''}`
    },
    id () {
      return `textarea-${this.name}`
    }
  },
  methods: {
    handleInput ($event: {target: {value: string}}) {
      if (this.limit) {
        $event.target.value = $event.target.value.substr(0, this.limit)
      }

      this.onInput($event)
    }
  }
})

export default class TextareaField extends Vue {}
