
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import MainButton from '@/components/base/buttons/MainButton.vue'
import Modal from '@/components/base/modal/Modal.vue'
import Card from '@/components/base/cards/Card.vue'

import soundNotificator from '@/libraries/soundNotificator'

import SoundNotificatorsState from '@/store/SoundNotificators'
import WorkShift from '@/store/WorkShift'

@Options({
  components: {
    MainButton,
    Modal,
    Card
  },
  methods: {
    ...createMapper(SoundNotificatorsState).mapMutations({
      setNeedLoadSoundNotificatorsPermission: 'setNeedLoadSoundNotificatorsPermission'
    }),
    load () {
      soundNotificator.load().catch(() => {
        this.setNeedLoadSoundNotificatorsPermission(true)
      })

      this.setNeedLoadSoundNotificatorsPermission(false)
    }
  },
  computed: {
    ...createMapper(SoundNotificatorsState).mapGetters({
      needLoadSoundNotificatorsPermission: 'needLoadSoundNotificatorsPermission'
    }),
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift'
    })
  },
  created () {
    if (!this.workShift) {
      return
    }

    if (!soundNotificator.isLoaded()) {
      this.setNeedLoadSoundNotificatorsPermission(true)
    }
  }
})

export default class SoundNotificatorsProvider extends mixins(TranslatableClassComponent) {
  translationGroup = 'squad_base'
}
