
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    value: String,
    onChange: Function,
    name: String,
    label: String,
    error: String,
    description: String,
    classes: String,
    disabled: Boolean,
    min: Number,
    max: Number,
    step: Number
  },
  computed: {
    classesList () {
      return `range-input ${this.classes} ${this.error ? 'error' : ''} ${this.prefix ? 'with-prefix' : ''}`
    },
    id () {
      return `range-input-${this.name}`
    }
  }
})

export default class RangeInput extends Vue {}
