import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "error-message"
}
const _hoisted_3 = {
  key: 1,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`form-group ${_ctx.classes}`)
  }, [
    _createElementVNode("label", { for: _ctx.labelFor }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ], 2))
}