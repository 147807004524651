const AUTH_CODE_TIMER_OBSERVER_NAME = 'auth_code_timer'
const LOCATION_OBSERVER_NAME = 'location'
const WORK_SHIFT_OBSERVER_NAME = 'work_shift'
const WORK_SHIFT_PAUSE_OBSERVER_NAME = 'pause'
const CALL_REPORTS_OBSERVER_NAME = 'call_reports'

const WORK_SHIFT_LOCAL_STORAGE_KEY = 'work_shift'
const LANGUAGE_LOCAL_STORAGE_KEY = 'language'
const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'auth_token'
const IDENTIFIER_LOCAL_STORAGE_KEY = 'identifier'
const LAST_PAUSE_LOCAL_STORAGE_KEY = 'last_pause'
const REPORTS_SCHEDULE_LOCAL_STORAGE_KEY = 'reports_schedule'
const GUARD_SETTINGS_LOCAL_STORAGE_KEY = 'guard_settings'

const CUSTOM_AXIOS_ERROR = 'custom_axios_error'

const EARTH_RADIUS = 6378137

export {
  AUTH_CODE_TIMER_OBSERVER_NAME,
  LOCATION_OBSERVER_NAME,
  WORK_SHIFT_OBSERVER_NAME,
  WORK_SHIFT_PAUSE_OBSERVER_NAME,
  WORK_SHIFT_LOCAL_STORAGE_KEY,
  CALL_REPORTS_OBSERVER_NAME,

  LANGUAGE_LOCAL_STORAGE_KEY,
  AUTH_TOKEN_LOCAL_STORAGE_KEY,
  IDENTIFIER_LOCAL_STORAGE_KEY,
  LAST_PAUSE_LOCAL_STORAGE_KEY,
  REPORTS_SCHEDULE_LOCAL_STORAGE_KEY,
  GUARD_SETTINGS_LOCAL_STORAGE_KEY,

  CUSTOM_AXIOS_ERROR,

  EARTH_RADIUS
}
