import { Store } from 'vuex'
import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import configuredAxios from '@/libraries/axiosClient'
import { Position } from '@/types/libraries/Position'
import { LocationResponse } from '@/types/api/responses/location/LocationResponse'
import { PatrolAreasResponse } from '@/types/api/responses/location/PatrolAreasResponse'
import { PatrolAreasType } from '@/types/entities/PatrolAreasType'
import { PatrolAreaType } from '@/types/entities/PatrolAreaType'
import { GMapPoint } from '@/types/libraries/GMapPoint'

class LocationState {
  position!: Position;
  unionPatrolAreas!: GMapPoint[][][]
  singlePatrolAreas!: PatrolAreaType[]
  inArea: boolean = true;
  areas: string[] = [];
  needActiveLocationPermission: boolean = false;
}

class LocationGetters extends Getters<LocationState> {
  get position (): Position {
    return this.state.position
  }

  get unionPatrolAreas () {
    return this.state.unionPatrolAreas
  }

  get singlePatrolAreas () {
    return this.state.singlePatrolAreas
  }

  get areas (): string[] {
    return this.state.areas
  }

  get inArea (): boolean | undefined {
    return this.state.inArea
  }

  get needActiveLocationPermission () {
    return this.state.needActiveLocationPermission
  }
}

class LocationMutations extends Mutations<LocationState> {
  setPosition (position: Position) {
    this.state.position = position
  }

  setPatrolAreas (patrolAreas: PatrolAreasType) {
    this.state.unionPatrolAreas = patrolAreas.union_patrol_areas
    this.state.singlePatrolAreas = patrolAreas.single_patrol_areas
  }

  setAreas (areas: string[]) {
    this.state.areas = areas
    this.state.inArea = !!areas.length
  }

  setNeedActiveLocationPermission (needActiveLocationPermission: boolean) {
    this.state.needActiveLocationPermission = needActiveLocationPermission
  }
}

class LocationActions extends Actions<
  LocationState,
  LocationGetters,
  LocationMutations,
  LocationActions
  > {
  protected store!: Store<any>

  $init (store: Store<any>) {
    super.$init(store)
    this.store = store
  }

  getLocation () {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition((geoLocationPosition) => {
        this.mutations.setNeedActiveLocationPermission(false)

        const position: Position = {
          lat: geoLocationPosition.coords.latitude,
          lng: geoLocationPosition.coords.longitude,
          accuracy: geoLocationPosition.coords.accuracy
        }

        this.setLocation(position).then(() => {
          resolve(position)
        })
      }, (error) => {
        if (error.code === 1) {
          this.mutations.setNeedActiveLocationPermission(true)
        }
      })
    })
  }

  setLocation (position: Position) {
    return new Promise(resolve => {
      this.mutations.setPosition(position)

      if (!this.store.getters['WorkShift/workShift']) {
        resolve(position)
        return
      }

      configuredAxios.put('/locations', position).then((response: { data: LocationResponse }) => {
        this.mutations.setAreas(response.data.data.areas)
        resolve(position)
      })
    })
  }

  getPatrolAreas () {
    return new Promise(resolve => {
      configuredAxios.get('/patrol-areas').then((response: { data: PatrolAreasResponse }) => {
        this.mutations.setPatrolAreas(response.data.data)
        resolve(response.data.data)
      })
    })
  }
}

export default new Module({
  state: LocationState,
  getters: LocationGetters,
  mutations: LocationMutations,
  actions: LocationActions
})
