import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "info"
}
const _hoisted_2 = { class: "active-call-info" }
const _hoisted_3 = { class: "car-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Map = _resolveComponent("Map")!
  const _component_UserCard = _resolveComponent("UserCard")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`main ${_ctx.showMap ? 'with-map' : ''} ${_ctx.showInfo ? 'with-info' : ''} ${!!_ctx.activeCall ? 'with-call' : ''}`)
  }, [
    (_ctx.position && _ctx.showMap)
      ? (_openBlock(), _createBlock(_component_Map, {
          key: 0,
          center: _ctx.position,
          height: _ctx.mapCardHeight,
          id: "main-map",
          zoom: 15,
          markers: _ctx.markers,
          polygons: _ctx.polygons,
          myLocationButton: true,
          direction: _ctx.direction
        }, null, 8, ["center", "height", "markers", "polygons", "direction"]))
      : _createCommentVNode("", true),
    (_ctx.showInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!!_ctx.activeCall)
            ? (_openBlock(), _createBlock(_component_Card, {
                key: 0,
                classes: "active-call"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_UserCard, {
                      image: _ctx.activeCall.client.photo || _ctx.defaultClientImage,
                      name: _ctx.activeCall.client.name,
                      phone: _ctx.activeCall.client.phone,
                      address: _ctx.activeCall.client.address,
                      trustedPhone: _ctx.activeCall.client.trusted_phone,
                      area: _ctx.activeCall.client.position.areas?.join(', ')
                    }, null, 8, ["image", "name", "phone", "address", "trustedPhone", "area"]),
                    _createVNode(_component_MainButton, {
                      text: _ctx.translate('open_in_map'),
                      onClick: _ctx.openInMap,
                      classes: "open-in-map-button"
                    }, null, 8, ["text", "onClick"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showSquad && _ctx.workShift)
            ? (_openBlock(), _createBlock(_component_Card, {
                key: 1,
                classes: "work-shift"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, " 🚔 " + _toDisplayString(_ctx.workShift.car.tail_number) + " " + _toDisplayString(_ctx.workShift.car.model) + " " + _toDisplayString(_ctx.workShift.car.car_number), 1),
                  _createVNode(_component_UserCard, {
                    image: _ctx.workShift.main_guard.avatar || _ctx.defaultGuardImage,
                    name: _ctx.workShift.main_guard.name,
                    phone: _ctx.workShift.main_guard.phone_number
                  }, null, 8, ["image", "name", "phone"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workShift.guards, (guard) => {
                    return (_openBlock(), _createBlock(_component_UserCard, {
                      key: guard.id,
                      image: guard.avatar || _ctx.defaultGuardImage,
                      name: guard.name,
                      phone: guard.phone_number
                    }, null, 8, ["image", "name", "phone"]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}