import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "report-content" }
const _hoisted_2 = { class: "call-details" }
const _hoisted_3 = { class: "call-period" }
const _hoisted_4 = { class: "call-client-name" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "report" }
const _hoisted_7 = { class: "report-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextareaField = _resolveComponent("TextareaField")!
  const _component_DenyButton = _resolveComponent("DenyButton")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    onClose: _ctx.handleCloseClick,
    title: _ctx.title,
    classes: "report-modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "call-client-avatar",
            style: _normalizeStyle({backgroundImage: `url(${_ctx.currentCall.client.photo || _ctx.defaultImageUrl})`})
          }, null, 4),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.timeRange), 1),
          _createElementVNode("div", _hoisted_4, " 👨 " + _toDisplayString(_ctx.currentCall.client.name), 1),
          (_ctx.currentCall.report?.details.admin_comment)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "report-reject-reason",
                innerHTML: `⛔️ ${_ctx.currentCall.report.details.admin_comment.replaceAll('\n', '<br>')}`
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_TextareaField, {
            placeholder: _ctx.translate('input_report'),
            value: _ctx.report,
            onInput: _cache[0] || (_cache[0] = ($event) => _ctx.report = $event.target.value),
            rows: 5,
            classes: "report-input",
            onCtrlEnterKeyup: _ctx.handleSubmit
          }, null, 8, ["placeholder", "value", "onCtrlEnterKeyup"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_DenyButton, {
            text: _ctx.translate('send_later'),
            onClick: _ctx.handleCloseClick
          }, null, 8, ["text", "onClick"]),
          _createVNode(_component_MainButton, {
            text: _ctx.translate('send'),
            onClick: _ctx.handleSubmit,
            disabled: !_ctx.report
          }, null, 8, ["text", "onClick", "disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}