export default class ArrayHelper {
  public static toObject (array: Array<object>, key: string): object {
    return array.reduce((object, item) => {
      return {
        ...object,
        [item[key]]: item
      }
    }, {})
  }
}
