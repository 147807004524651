
import { mixins, Options } from 'vue-class-component'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import MainButton from '@/components/base/buttons/MainButton.vue'
import Modal from '@/components/base/modal/Modal.vue'
import RangeInput from '@/components/base/inputs/RangeInput.vue'
import SecondaryButton from '@/components/base/buttons/SecondaryButton.vue'
import TextareaField from '@/components/base/inputs/TextareaField.vue'

@Options({
  props: {
    onClose: Function,
    onSubmit: Function,
    isExtension: Boolean
  },
  data () {
    return {
      pauseReason: '',
      pauseTimeRange: 5
    }
  },
  components: {
    SecondaryButton,
    RangeInput,
    MainButton,
    Modal,
    TextareaField
  },
  computed: {
    pauseReasons (): string[] {
      return this.getAllGroupTranslations('squad_pause_reasons')
    }
  },
  methods: {
    handleSubmit () {
      this.onSubmit(this.pauseTimeRange * 60, this.pauseReason)
    }
  }
})

export default class PauseModal extends mixins(TranslatableClassComponent, TranslatableSwal) {
  translationGroup = 'squad_base'
}
