import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "calls" }
const _hoisted_2 = { class: "call-time-range" }
const _hoisted_3 = { class: "call-item-block" }
const _hoisted_4 = { class: "call-info" }
const _hoisted_5 = { class: "client" }
const _hoisted_6 = { class: "client-name" }
const _hoisted_7 = {
  key: 0,
  class: "client-phone"
}
const _hoisted_8 = {
  key: 1,
  class: "client-address"
}
const _hoisted_9 = {
  key: 0,
  class: "report"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "call-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (call) => {
      return (_openBlock(), _createBlock(_component_Card, {
        classes: `call-item ${call.report?.status === 'pending' ? 'report-pending' : ''}`,
        key: call.id
      }, {
        title: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(new Date(call.created_at.replaceAll('-', '/')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })) + " — " + _toDisplayString(call.status === 'finished' ? new Date(call.finished_at.replaceAll('-', '/')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '...'), 1),
          (call.status !== 'finished')
            ? (_openBlock(), _createBlock(_component_Badge, {
                key: 0,
                background: "#156cd7",
                color: "#ffffff"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('statuses.live')), 1)
                ]),
                _: 1
              }))
            : (!call.report)
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 1,
                  background: "#ffc800",
                  color: "#ffffff"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('statuses.unreported')), 1)
                  ]),
                  _: 1
                }))
              : (call.report.status === 'pending')
                ? (_openBlock(), _createBlock(_component_Badge, {
                    key: 2,
                    background: "#ff9900",
                    color: "#ffffff"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate('statuses.report_pending')), 1)
                    ]),
                    _: 1
                  }))
                : (call.report.status === 'rejected')
                  ? (_openBlock(), _createBlock(_component_Badge, {
                      key: 3,
                      background: "#950000",
                      color: "#ffffff"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('statuses.report_rejected')), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_Badge, {
                      key: 4,
                      background: "#1f6601",
                      color: "#ffffff"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('statuses.reported')), 1)
                      ]),
                      _: 1
                    }))
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "client-avatar",
              style: _normalizeStyle({backgroundImage: `url(${call.client.photo || _ctx.defaultClientImage})`})
            }, null, 4),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, " 👨 " + _toDisplayString(call.client.name), 1),
                (call.client.phone)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 📞 " + _toDisplayString(call.client.phone), 1))
                  : _createCommentVNode("", true),
                (call.client.address)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, " 📪 " + _toDisplayString(call.client.address), 1))
                  : _createCommentVNode("", true)
              ]),
              (call.report)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", {
                      innerHTML: `✏️ ${call.report.details.text.replaceAll('\n', '<br>')}`
                    }, null, 8, _hoisted_10),
                    (call.report.details.admin_comment)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          innerHTML: `⛔️ ${call.report.details.admin_comment.replaceAll('\n', '<br>')}`
                        }, null, 8, _hoisted_11))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (call.status === 'finished' && (!call.report || call.report.status !== 'approved'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (!call.report || call.report.status === 'rejected')
                  ? (_openBlock(), _createBlock(_component_MainButton, {
                      key: 0,
                      text: _ctx.translate('write_report'),
                      onClick: () => _ctx.writeReport(call.id)
                    }, null, 8, ["text", "onClick"]))
                  : _createCommentVNode("", true),
                (call.report?.status === 'pending')
                  ? (_openBlock(), _createBlock(_component_MainButton, {
                      key: 1,
                      text: _ctx.translate('change_report'),
                      onClick: () => _ctx.changeReport(call.id)
                    }, null, 8, ["text", "onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["classes"]))
    }), 128))
  ]))
}