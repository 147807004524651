
import { Options, mixins } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import router from '@/router'
import observer from '@/libraries/observer'

import { AUTH_CODE_TIMER_OBSERVER_NAME } from '@/constants'

import BaseButton from '@/components/base/buttons/BaseButton.vue'
import Card from '@/components/base/cards/Card.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import NumberInput from '@/components/base/inputs/NumberInput.vue'
import PhoneInput from '@/components/base/inputs/PhoneInput.vue'

import HttpErrorsHandler from '@/components/abstaract/HttpErrorsHandler'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import { AuthHashResponse } from '@/types/api/responses/auth/AuthHashResponse'
import { DefaultResponse } from '@/types/api/responses/DefaultResponse'

import soundNotificator from '@/libraries/soundNotificator'

import Auth from '@/store/Auth'
import Config from '@/store/Config'
import SoundNotificatorsState from '@/store/SoundNotificators'

@Options({
  components: {
    BaseButton,
    NumberInput,
    PhoneInput,
    Card,
    MainButton
  },
  data () {
    return {
      phone: '',
      phoneError: '',
      code: '',
      codeError: '',
      codeExpired: false,
      timeLeft: undefined,
      timeLimit: undefined
    }
  },
  methods: {
    ...createMapper(Auth).mapActions({
      getAuthCode: 'getAuthCode',
      sendAuthCode: 'sendAuthCode'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    ...createMapper(SoundNotificatorsState).mapMutations({
      setNeedLoadSoundNotificatorsPermission: 'setNeedLoadSoundNotificatorsPermission'
    }),
    setTimer (time: number) {
      const timeLeft = this.timeLimit - time
      if (timeLeft <= 0) {
        this.clearCodeTimer()
        this.codeExpired = true
        return
      }

      this.timeLeft = timeLeft
    },
    clearCodeTimer () {
      this.timeLeft = 0
      this.code = ''
      this.codeError = ''
      observer.unsubscribe(AUTH_CODE_TIMER_OBSERVER_NAME)
    },
    handlePhoneChange (phone: string) {
      this.phone = phone
    },
    handleAuthCodeInput (code: string) {
      this.code = code
    },
    handleAuthCodeChange () {
      this.codeError = this.code.length !== this.smsCodeLength ? this.translate('code_error') : ''
    },
    auth () {
      if (this.phone.length !== 12) {
        this.phoneError = this.translate('phone_error', 'squad_validators')
        return
      }

      this.phoneError = ''
      this.setFetching(true)
      this.getAuthCode(this.phone).then((authResponse: AuthHashResponse) => {
        const timestamp = Math.round(new Date().getTime() / 1000)
        this.timeLimit = timestamp + this.smsCodeLifeTime
        this.timeLeft = this.smsCodeLifeTime
        this.codeExpired = false
        this.code = authResponse.data.code || ''
        observer.subscribe(this.setTimer, AUTH_CODE_TIMER_OBSERVER_NAME)
        this.setFetching(false)
      }).catch((error: DefaultResponse) => {
        this.httpErrorHandler(error)
        this.setFetching(false)
      })
    },
    sendCode () {
      if (this.code.length !== this.smsCodeLength) {
        this.codeError = this.translate('code_error')
        return
      }

      this.codeError = ''

      this.setFetching(true)
      this.sendAuthCode(this.code).then(async () => {
        try {
          await soundNotificator.load()
        } catch {
          this.setNeedLoadSoundNotificatorsPermission(true)
        } finally {
          this.setFetching(false)
          router.push('/work-shift')
        }
      }).catch((error: DefaultResponse) => {
        this.httpErrorHandler(error)
        this.setFetching(false)
      })
    }
  },
  computed: {
    ...createMapper(Config).mapGetters({
      smsCodeLength: 'smsCodeLength',
      smsCodeLifeTime: 'smsCodeLifeTime'
    }),
    timer () {
      return Math.floor(this.timeLeft / 60) + ':' + ('0' + Math.floor(this.timeLeft % 60)).slice(-2)
    }
  },
  created () {
    this.fieldsHandlersForValidationHandler = {
      phone: (translatedError: string) => {
        this.phoneError = translatedError
      },
      code: (translatedError: string) => {
        this.codeError = translatedError
      }
    }
  }
})

export default class Authorization extends mixins(TranslatableClassComponent, TranslatableSwal, HttpErrorsHandler) {
  translationGroup = 'squad_auth_screen'
}
