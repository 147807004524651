
import { Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import Config from '@/store/Config'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

@Options({
  computed: {
    ...createMapper(Config).mapGetters({
      isOnline: 'isOnline'
    })
  }
})

export default class ConnectionProvider extends TranslatableClassComponent {
  translationGroup = 'squad_base'
}
