import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import configuredAxios from '@/libraries/axiosClient'
import { OfficeType } from '@/types/entities/OfficeType'
import { OfficeResponse } from '@/types/api/responses/office/OfficeResponse'

class OfficeState {
  offices?: OfficeType[]
}

class OfficeGetters extends Getters<OfficeState> {
  get offices () {
    return this.state.offices
  }
}

class OfficeMutations extends Mutations<OfficeState> {
  setOffices (offices: OfficeType[]) {
    this.state.offices = offices
  }
}

class OfficeActions extends Actions<
  OfficeState,
  OfficeGetters,
  OfficeMutations,
  OfficeActions
  > {
  getOffices () {
    return new Promise(resolve => {
      configuredAxios.get('/offices').then((response: {data: OfficeResponse}) => {
        this.mutations.setOffices(response.data.data)
        resolve(response.data)
      })
    })
  }
}

export default new Module({
  state: OfficeState,
  getters: OfficeGetters,
  mutations: OfficeMutations,
  actions: OfficeActions
})
