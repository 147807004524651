import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import configuredAxios from '@/libraries/axiosClient'
import { GUARD_SETTINGS_LOCAL_STORAGE_KEY } from '@/constants'
import { Config } from '@/types/api/responses/config/Config'
import { GuardSettings } from '@/types/libraries/GuardSettings'

function getGuardsSettings (): GuardSettings {
  const guardSettings = localStorage.getItem(GUARD_SETTINGS_LOCAL_STORAGE_KEY)
  return guardSettings ? JSON.parse(guardSettings) : {}
}

// @ToDo head request for actualize config via observer

class ConfigState {
  update_coordinates_period: number = 5;
  check_work_shift_period: number = 5;
  remind_call_report_period: number = 900;
  max_distance_to_client_for_arriving: number = 100;
  sms_code_length: number = 4;
  sms_code_life_time: number = 60;
  showMap!: boolean;
  showSquad!: boolean;
  isFetching: boolean = false;
  isOnline: boolean = window.navigator.onLine;
  isNewVersionAvailable: boolean = false;

  constructor () {
    const guardSettings = getGuardsSettings()
    this.showMap = typeof guardSettings?.showMap === 'undefined' || guardSettings.showMap
    this.showSquad = typeof guardSettings?.showSquad === 'undefined' || guardSettings.showSquad
  }
}

class ConfigGetters extends Getters<ConfigState> {
  get updateCoordinatesPeriod (): number {
    return this.state.update_coordinates_period
  }

  get checkWorkShiftPeriod (): number {
    return this.state.check_work_shift_period
  }

  get remindCallReportPeriod (): number {
    return this.state.remind_call_report_period
  }

  get maxDistanceToClientForArriving (): number {
    return this.state.max_distance_to_client_for_arriving
  }

  get smsCodeLength (): number {
    return this.state.sms_code_length
  }

  get smsCodeLifeTime (): number {
    return this.state.sms_code_life_time
  }

  get showMap (): boolean {
    return this.state.showMap
  }

  get showSquad (): boolean {
    return this.state.showSquad
  }

  get isFetching (): boolean {
    return this.state.isFetching
  }

  get isOnline (): boolean {
    return this.state.isOnline
  }

  get isNewVersionAvailable (): boolean {
    return this.state.isNewVersionAvailable
  }
}

class ConfigMutations extends Mutations<ConfigState> {
  setConfig (config: Config) {
    if (config?.squads?.update_coordinates?.active) {
      this.state.update_coordinates_period = Number(config.squads.update_coordinates.active)
    }

    if (config?.squads?.check_work_shift_period) {
      this.state.check_work_shift_period = Number(config.squads.check_work_shift_period)
    }

    if (config?.squads?.remind_call_report_period) {
      this.state.remind_call_report_period = Number(config.squads.remind_call_report_period)
    }

    if (config?.squads?.max_distance_to_client_for_arriving) {
      this.state.max_distance_to_client_for_arriving = Number(config.squads.max_distance_to_client_for_arriving)
    }

    if (config?.general?.sms_code?.length) {
      this.state.sms_code_length = Number(config.general.sms_code.length)
    }

    if (config?.general?.sms_code?.life_time) {
      this.state.sms_code_life_time = Number(config.general.sms_code.life_time)
    }
  }

  setShowMap (showMap: boolean) {
    this.state.showMap = showMap
    const guardSettings = getGuardsSettings()
    guardSettings.showMap = showMap
    localStorage.setItem(GUARD_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(guardSettings))
  }

  setShowSquad (showSquad: boolean) {
    this.state.showSquad = showSquad
    const guardSettings = getGuardsSettings()
    guardSettings.showSquad = showSquad
    localStorage.setItem(GUARD_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(guardSettings))
  }

  setFetching (isFetching: boolean) {
    this.state.isFetching = isFetching
  }

  setIsOnline (isOnline: boolean) {
    this.state.isOnline = isOnline
  }

  setIsNewVersionAvailable (isNewVersionAvailable: boolean) {
    this.state.isNewVersionAvailable = isNewVersionAvailable
  }
}

class ConfigActions extends Actions<
  ConfigState,
  ConfigGetters,
  ConfigMutations,
  ConfigActions
  > {
  getConfig () {
    return new Promise(resolve => {
      configuredAxios.get(process.env.VUE_APP_CONFIG_URL).then((response: { data: Config }) => {
        this.mutations.setConfig(response.data)
        resolve(response.data)
      })
    })
  }
}

export default new Module({
  state: ConfigState,
  getters: ConfigGetters,
  mutations: ConfigMutations,
  actions: ConfigActions
})
