import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "banner-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`big-banner ${_ctx.bannerClass}`)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.installationPrompt)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.translate('install_pwa_banner')), 1),
                    _createElementVNode("div", null, [
                      _createVNode(_component_SecondaryButton, {
                        text: _ctx.translate('install'),
                        class: "install-pwa-button",
                        onClick: _ctx.install
                      }, null, 8, ["text", "onClick"])
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.translate('use_pwa_banner')), 1)
                  ], 64))
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}