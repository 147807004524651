
import { Options, Vue } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'
import Config from '@/store/Config'

@Options({
  data () {
    return {
      isLocalFetching: false
    }
  },
  methods: {
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    ...createMapper(Config).mapActions({
      getConfig: 'getConfig'
    })
  },
  created () {
    this.isLocalFetching = true
    this.getConfig().then(() => {
      this.isLocalFetching = false
    }).catch(() => {
      this.isLocalFetching = false
    })
  },
  watch: {
    isLocalFetching (isFetching: boolean) {
      this.setFetching(isFetching)
    }
  }
})

export default class ConfigProvider extends Vue {}
