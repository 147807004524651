import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyButton = _resolveComponent("EmptyButton")!
  const _component_PauseModal = _resolveComponent("PauseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!_ctx.workShift && _ctx.workShift.status === 'available' && _ctx.workShift.last_pause?.status !== 'pending')
      ? (_openBlock(), _createBlock(_component_EmptyButton, {
          key: 0,
          text: "⏸",
          classes: "pause-work-shift",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pauseModal = true))
        }))
      : _createCommentVNode("", true),
    (!!_ctx.workShift && _ctx.workShift.status === 'paused' && !_ctx.hasPendingExtendingPauseRequest)
      ? (_openBlock(), _createBlock(_component_EmptyButton, {
          key: 1,
          text: "➡️",
          classes: "extension-work-shift-pause",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.extensionPauseModal = true))
        }))
      : _createCommentVNode("", true),
    (!!_ctx.workShift && (_ctx.workShift.status === 'paused' || _ctx.workShift.last_pause?.status === 'pending'))
      ? (_openBlock(), _createBlock(_component_EmptyButton, {
          key: 2,
          text: "▶️",
          classes: "continue-work-shift",
          onClick: _ctx.handleResumeClick
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.pauseModal)
      ? (_openBlock(), _createBlock(_component_PauseModal, {
          key: 3,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pauseModal = false)),
          onSubmit: _ctx.handlePauseClick
        }, null, 8, ["onSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.extensionPauseModal)
      ? (_openBlock(), _createBlock(_component_PauseModal, {
          key: 4,
          isExtension: true,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.extensionPauseModal = false)),
          onSubmit: _ctx.handleExtensionPauseClick
        }, null, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}