import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import configuredAxios from '@/libraries/axiosClient'
import { LANGUAGE_LOCAL_STORAGE_KEY } from '@/constants'
import { TranslationsResponse } from '@/types/api/responses/translations/TranslationsResponse'
import type { Languages } from '@/types/api/responses/translations/Languages'

// @ToDo head request for actualize translations via observer

const defaultLanguage: Languages = 'ru' as Languages

class TranslationsState {
  language: Languages = defaultLanguage;
  translations: TranslationsResponse | undefined;

  constructor () {
    const storedLanguage = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY)
    this.language = storedLanguage ? storedLanguage as Languages : defaultLanguage
  }
}

class TranslationsGetters extends Getters<TranslationsState> {
  getTranslation (translationGroup: string, translationKey: string) {
    const key = `${translationGroup}[${translationKey}]`
    const currentLanguageGroupTranslations = this.getters.getGroupTranslations(translationGroup)
    if (!currentLanguageGroupTranslations || !Object.values(currentLanguageGroupTranslations).length) {
      return key
    }

    const translationEntry = Object.entries(currentLanguageGroupTranslations).find((element) => element[0] === translationKey)
    if (!translationEntry) {
      return key
    }

    return translationEntry[1]
  }

  getTranslationsByGroup (translationGroup: string): string[] {
    const currentLanguageGroupTranslations = this.getters.getGroupTranslations(translationGroup)
    if (!currentLanguageGroupTranslations || !Object.values(currentLanguageGroupTranslations).length) {
      return []
    }

    return Object.values(currentLanguageGroupTranslations)
  }

  getGroupTranslations (translationGroup: string): Record<string, string> | null {
    const translations = this.state.translations
    if (typeof translations === 'undefined') {
      return null
    }

    const currentLanguageTranslations = translations[this.state.language]
    if (!currentLanguageTranslations || !Object.keys(currentLanguageTranslations).includes(translationGroup)) {
      return null
    }

    const currentLanguageGroupTranslationsEntry = Object.entries(currentLanguageTranslations).find((element) => element[0] === translationGroup)
    if (!currentLanguageGroupTranslationsEntry) {
      return null
    }

    return currentLanguageGroupTranslationsEntry[1]
  }
}

class TranslationsMutations extends Mutations<TranslationsState> {
  setTranslations (translations: TranslationsResponse) {
    this.state.translations = translations
  }
}

class TranslationsActions extends Actions<
  TranslationsState,
  TranslationsGetters,
  TranslationsMutations,
  TranslationsActions
  > {
  getTranslations () {
    return new Promise(resolve => {
      configuredAxios.get(process.env.VUE_APP_TRANSLATIONS_URL).then((response: { data: TranslationsResponse }) => {
        this.mutations.setTranslations(response.data)
        resolve(response.data)
      })
    })
  }
}

export default new Module({
  state: TranslationsState,
  getters: TranslationsGetters,
  mutations: TranslationsMutations,
  actions: TranslationsActions
})
