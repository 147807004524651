import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CallReportModal = _resolveComponent("CallReportModal")!
  const _component_CallConfirmationModal = _resolveComponent("CallConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.reportModal)
      ? (_openBlock(), _createBlock(_component_CallReportModal, {
          key: 0,
          currentCall: _ctx.currentReportCall,
          onClose: _ctx.handleModalClose,
          title: _ctx.translate(_ctx.currentReportCall.report?.status === 'pending' ? 'change_report' : 'add_report')
        }, null, 8, ["currentCall", "onClose", "title"]))
      : _createCommentVNode("", true),
    (_ctx.isActiveUnconfirmed)
      ? (_openBlock(), _createBlock(_component_CallConfirmationModal, {
          key: 1,
          onConfirm: _ctx.resetUnconfirmedFlag
        }, null, 8, ["onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}