
import { Options, Vue } from 'vue-class-component'
import Card from '@/components/base/cards/Card.vue'

@Options({
  components: { Card },
  props: {
    image: String,
    name: String,
    phone: String,
    address: String,
    trustedPhone: String,
    area: String
  }
})

export default class UserCard extends Vue {}
