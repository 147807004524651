
import { Options, Vue } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'
import observer from '@/libraries/observer'
import { WORK_SHIFT_OBSERVER_NAME } from '@/constants'
import Auth from '@/store/Auth'
import Config from '@/store/Config'
import WorkShift from '@/store/WorkShift'

@Options({
  data () {
    return {
      isLocalFetching: false
    }
  },
  methods: {
    ...createMapper(WorkShift).mapActions({
      getCurrent: 'getCurrent'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    getWorkShiftIfNeeded () {
      if (this.isAuth) {
        this.getCurrent()
      }
    }
  },
  computed: {
    ...createMapper(Auth).mapGetters({
      isAuth: 'isAuth',
      token: 'token'
    }),
    ...createMapper(Config).mapGetters({
      checkWorkShiftPeriod: 'checkWorkShiftPeriod'
    })
  },
  created () {
    if (!this.token) {
      return
    }

    this.isLocalFetching = true
    this.getCurrent().then(() => {
      this.isLocalFetching = false
    }).catch(() => {
      this.isLocalFetching = false
    })

    observer.subscribe(this.getWorkShiftIfNeeded, WORK_SHIFT_OBSERVER_NAME, this.checkWorkShiftPeriod)
  },
  watch: {
    isLocalFetching (isFetching: boolean) {
      this.setFetching(isFetching)
    }
  }
})

export default class WorkShiftProvider extends Vue {}
