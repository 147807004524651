import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "range-input-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["name", "value", "id", "disabled", "min", "max", "step"]
const _hoisted_5 = {
  key: 1,
  class: "error-message"
}
const _hoisted_6 = {
  key: 2,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(`range-input-row ${_ctx.disabled ? 'disabled' : ''}`)
    }, [
      _createElementVNode("input", {
        type: "range",
        name: _ctx.name,
        value: _ctx.value,
        id: _ctx.id,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        disabled: _ctx.disabled,
        class: _normalizeClass(_ctx.classesList),
        min: _ctx.min,
        max: _ctx.max,
        step: _ctx.step
      }, null, 42, _hoisted_4)
    ], 2),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ]))
}