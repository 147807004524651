import { Store } from 'vuex'
import { Getters, Mutations, Actions, Module } from 'vuex-smart-module'
import configuredAxios from '@/libraries/axiosClient'
import { IDENTIFIER_LOCAL_STORAGE_KEY, AUTH_TOKEN_LOCAL_STORAGE_KEY, WORK_SHIFT_LOCAL_STORAGE_KEY } from '@/constants'
import { AuthHashResponse } from '@/types/api/responses/auth/AuthHashResponse'
import { DefaultResponse } from '@/types/api/responses/DefaultResponse'
import { TokenResponse } from '@/types/api/responses/auth/TokenResponse'

const getIdentifier: () => string = () => {
  let identifier = localStorage.getItem(IDENTIFIER_LOCAL_STORAGE_KEY)
  if (!identifier) {
    identifier = btoa(navigator.userAgent + Math.random().toString(36)).slice(0, 50)
    localStorage.setItem(IDENTIFIER_LOCAL_STORAGE_KEY, identifier)
  }

  return identifier
}

class AuthState {
  hash: string = '';

  token: string = '';

  constructor () {
    this.token = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY) || ''
  }
}

class AuthGetters extends Getters<AuthState> {
  get token () {
    return this.state.token
  }

  get isAuth () {
    return !!this.state.token
  }
}

class AuthMutations extends Mutations<AuthState> {
  setHash (hash: string) {
    this.state.hash = hash
  }

  setToken (token: string) {
    this.state.token = token
    localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, token)
    configuredAxios.defaults.headers.common.Authorization = 'Bearer ' + token
  }

  logout () {
    this.state.token = ''
    localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)
    localStorage.removeItem(WORK_SHIFT_LOCAL_STORAGE_KEY)
    configuredAxios.defaults.headers.common.Authorization = ''
  }
}

class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
  > {
  protected store!: Store<any>

  $init (store: Store<any>) {
    super.$init(store)
    this.store = store
  }

  getAuthCode (phone: string) {
    return new Promise((resolve, reject) => {
      configuredAxios.post('/auth', {
        identifier: getIdentifier(),
        language: 'ru',
        phone: phone
      }).then((response: {data: AuthHashResponse}) => {
        this.mutations.setHash(response.data.data.hash)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  sendAuthCode (code: string) {
    return new Promise((resolve, reject) => {
      configuredAxios.post('/auth/code', {
        code,
        hash: this.state.hash
      }).then((response: {data: TokenResponse}) => {
        this.mutations.setToken(response.data.data.access_token)
        this.store.dispatch('WorkShift/getCurrent').then(() => {
          resolve(response.data)
        }).catch(() => {
          resolve(response.data)
        })
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  logout (finishWorkShift: boolean) {
    return new Promise((resolve, reject) => {
      configuredAxios.post('/auth/logout', { finish_work_shift: finishWorkShift }).then((response: {data: DefaultResponse}) => {
        if (response.data.code === 205) {
          this.mutations.logout()
          this.store.commit('WorkShift/remove')
          this.store.commit('Call/remove')
        }
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }
}

export default new Module({
  state: AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions
})
