import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pause-time-range" }
const _hoisted_2 = { class: "pause-reasons" }
const _hoisted_3 = { class: "reason-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RangeInput = _resolveComponent("RangeInput")!
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!
  const _component_TextareaField = _resolveComponent("TextareaField")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: _ctx.translate(_ctx.isExtension ? 'extension_pause_reason' : 'pause_reason'),
    classes: "pause-modal",
    onClose: _ctx.onClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(" ⏱ " + _toDisplayString(_ctx.translateWithVariables(_ctx.isExtension ? 'extension_pause' : 'get_pause', {minutes: _ctx.pauseTimeRange})) + " ", 1),
        _createVNode(_component_RangeInput, {
          min: 5,
          max: 120,
          step: 5,
          value: _ctx.pauseTimeRange,
          onInput: _cache[0] || (_cache[0] = ($event) => _ctx.pauseTimeRange = $event.target.value)
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pauseReasons, (reason) => {
          return (_openBlock(), _createBlock(_component_SecondaryButton, {
            class: "pause-reason",
            onClick: ($event: any) => (_ctx.pauseReason = reason),
            key: reason,
            text: reason
          }, null, 8, ["onClick", "text"]))
        }), 128))
      ]),
      _createVNode(_component_TextareaField, {
        placeholder: _ctx.translate('input_pause_reason'),
        value: _ctx.pauseReason,
        onInput: _cache[1] || (_cache[1] = ($event) => _ctx.pauseReason = $event.target.value),
        onCtrlEnterKeyup: _ctx.handleSubmit,
        rows: 3
      }, null, 8, ["placeholder", "value", "onCtrlEnterKeyup"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MainButton, {
          text: _ctx.translate('confirm', 'squad_base'),
          onClick: _ctx.handleSubmit,
          disabled: !_ctx.pauseReason
        }, null, 8, ["text", "onClick", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}