import { Vue } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'
import Translations from '@/store/Translations'
import { TranslationData } from '@/types/api/responses/Error'

export default class TranslatableClassComponent extends Vue {
  protected translationGroup: string = ''

  private getTranslationGetter?: () => (translationGroup: string, translationKey: string) => string

  private getTranslationsByGroup?: () => (translationGroup: string) => string[]

  protected translate (translationKey: string, translationGroup: string = this.translationGroup): string {
    return this.getTranslation(translationGroup, translationKey)
  }

  protected translateByFullKey (fullKey: string): string {
    const separatedTranslationKeyParts = fullKey.split('.')
    if (separatedTranslationKeyParts.length < 2) {
      return fullKey
    }

    const [translationGroup, ...translationKeyParts] = separatedTranslationKeyParts
    return this.getTranslation(translationGroup, translationKeyParts.join('.'))
  }

  protected translateWithVariables (translationKey: string, replacements: Record<string, string>, translationGroup = this.translationGroup): string {
    let translation = this.getTranslation(translationGroup, translationKey)

    Object.entries(replacements).forEach(([replacementKey, replacementValue]) => {
      translation = translation.replace(`:${replacementKey}`, replacementValue)
    })

    return translation
  }

  protected getAllGroupTranslations (translationGroup = this.translationGroup): string[] {
    if (typeof this.getTranslationsByGroup === 'undefined') {
      this.getTranslationsByGroup = createMapper(Translations).mapGetters({
        getTranslationsByGroup: 'getTranslationsByGroup'
      }).getTranslationsByGroup
    }

    return this.getTranslationsByGroup()(translationGroup)
  }

  protected translateValidationError (translationData: TranslationData): string {
    let translate = this.translateByFullKey(translationData.translation_key)
    if (typeof translationData.replacements !== 'object' || !Object.keys(translationData.replacements)) {
      return translate
    }

    Object.entries(translationData.replacements).forEach(([replacementKey, replacementData]) => {
      let replacementValue
      if (replacementData.type === 'scalar') {
        replacementValue = replacementData.value
      } else {
        replacementValue = this.translateByFullKey(replacementData.value)
      }

      translate = translate.replace(`:${replacementKey}`, replacementValue)
    })
    return translate
  }

  private getTranslation (translationGroup: string, translationKey: string): string {
    if (typeof this.getTranslationGetter === 'undefined') {
      this.getTranslationGetter = createMapper(Translations).mapGetters({
        getTranslation: 'getTranslation'
      }).getTranslation
    }

    return this.getTranslationGetter()(translationGroup, translationKey)
  }
}
