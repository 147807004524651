import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!

  return (_openBlock(), _createBlock(_component_NumberInput, {
    value: _ctx.phone || '380',
    error: _ctx.errorMessage,
    label: _ctx.label,
    prefix: "+",
    onInput: _ctx.onInput,
    onChange: _ctx.handleChange,
    onEnterKeyup: _ctx.onEnterKeyup,
    disabled: _ctx.disabled,
    limit: 12
  }, null, 8, ["value", "error", "label", "onInput", "onChange", "onEnterKeyup", "disabled"]))
}