import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "reason-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: '🚨 ' + _ctx.translate('new_unconfirmed_call'),
    classes: "call-confirmation-modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MainButton, {
          text: _ctx.translate('confirm_new_call'),
          onClick: _ctx.confirmCall
        }, null, 8, ["text", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}