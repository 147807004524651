import { Store } from 'vuex'
import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'

import configuredAxios from '@/libraries/axiosClient'

import { CallType } from '@/types/entities/CallType'
import { CallsResponse } from '@/types/api/responses/call/CallsResponse'
import { CallResponse } from '@/types/api/responses/call/CallResponse'
import { DefaultResponse } from '@/types/api/responses/DefaultResponse'
import { PaginationRequest } from '@/types/api/requests/PaginationRequest'
import { ReportRequest } from '@/types/api/requests/call/ReportRequest'
import { Status as WorkShiftStatus } from '@/types/api/responses/workShift/Status'
import { Status as CallStatus } from '@/types/api/responses/call/Status'
import { WorkShiftResponse } from '@/types/api/responses/workShift/WorkShiftResponse'

const getWorkShiftStatus = (callStatus: CallStatus) => {
  switch (callStatus) {
    case 'pending':
      return 'call_without_approved' as WorkShiftStatus
    case 'squad_in_road':
      return 'call_moving' as WorkShiftStatus
    case 'squad_arrived':
      return 'call_arrived' as WorkShiftStatus
    default:
      return 'available' as WorkShiftStatus
  }
}

class CallState {
  active?: CallType;
  list: CallType[] = [];
  isActiveUnconfirmed: boolean = false;
}

class CallGetters extends Getters<CallState> {
  get active (): CallType | undefined {
    return this.state.active
  }

  get list (): CallType[] {
    return this.state.list
  }

  get isActiveUnconfirmed (): boolean {
    return this.state.isActiveUnconfirmed
  }
}

class CallMutations extends Mutations<CallState> {
  setActive (call?: CallType) {
    this.state.active = !call || call.status === 'finished' ? undefined : call
  }

  remove () {
    this.state.active = undefined
  }

  setList (list: CallType[]) {
    this.state.list = list
  }

  setIsActiveUnconfirmed (isActiveUnconfirmed: boolean) {
    this.state.isActiveUnconfirmed = isActiveUnconfirmed
  }
}

class CallActions extends Actions<
  CallState,
  CallGetters,
  CallMutations,
  CallActions
  > {
  protected store!: Store<any>

  $init (store: Store<any>) {
    super.$init(store)
    this.store = store
  }

  changeStatus (status: CallStatus) {
    return new Promise((resolve, reject) => {
      if (!this.state.active) {
        return
      }

      configuredAxios.patch(`/calls/${this.state.active.id}`, { status }).then((response: {data: CallResponse}) => {
        this.mutations.setActive(response.data.data)

        this.store.commit('WorkShift/changeStatus', getWorkShiftStatus(response.data.data.status as CallStatus))
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        if (error.code === 404) {
          this.mutations.remove()
          this.store.commit('WorkShift/changeStatus', 'available' as WorkShiftStatus)
        }

        reject(error)
      })
    })
  }

  report (reportRequest: ReportRequest) {
    return new Promise((resolve, reject) => {
      configuredAxios.post(`/calls/${reportRequest.callId}/report`, { text: reportRequest.text }).then((response: {data: WorkShiftResponse}) => {
        this.store.commit('WorkShift/setWorkShift', response.data.data)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  getList (paginationConfig: PaginationRequest) {
    return new Promise((resolve, reject) => {
      configuredAxios.get(`/calls?before_id=${paginationConfig.before_id || ''}&after_id=${paginationConfig.after_id || ''}&limit=${paginationConfig.limit || ''}`).then((response: {data: CallsResponse}) => {
        this.mutations.setList(response.data.data.calls)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }
}

export default new Module({
  state: CallState,
  getters: CallGetters,
  mutations: CallMutations,
  actions: CallActions
})
