
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    classes: String
  }
})

export default class Card extends Vue {}
