
import { mixins, Options } from 'vue-class-component'

import Badge from '@/components/base/decorations/Badge.vue'
import SecondaryButton from '@/components/base/buttons/SecondaryButton.vue'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

@Options({
  data () {
    return {
      show: false,
      installationPrompt: null
    }
  },
  components: {
    Badge,
    SecondaryButton
  },
  computed: {
    bannerClass () {
      return this.installationPrompt ? 'big-banner-not-installed' : 'big-banner-installed'
    }
  },
  methods: {
    isPwa () {
      // For iOS
      if ((window.navigator as any).standalone) return true

      // For Android
      if (window.matchMedia('(display-mode: standalone)').matches) return true

      return false
    },
    async install () {
      this.installationPrompt.prompt()
    }
  },
  created () {
    this.show = !this.isPwa()

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault()
      this.installationPrompt = event
    })

    window.addEventListener('appinstalled', () => {
      this.installationPrompt = null
      this.show = false
    })
  }
})

export default class PwaBanner extends mixins(TranslatableClassComponent) {
  translationGroup = 'squad_base'
}
