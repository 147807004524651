
import { Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import { GMapPoint } from '@/types/libraries/GMapPoint'
import { PatrolAreaType } from '@/types/entities/PatrolAreaType'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

import Call from '@/store/Call'
import Location from '@/store/Location'
import WorkShift from '@/store/WorkShift'

import Card from '@/components/base/cards/Card.vue'
import Config from '@/store/Config'
import MainButton from '@/components/base/buttons/MainButton.vue'
import Map from '@/components/base/map/Map.vue'
import UserCard from '@/components/base/cards/UserCard.vue'

@Options({
  data () {
    return {
      pageWidth: undefined,
      pageHeight: undefined,
      showWorkShiftInfo: false
    }
  },
  components: {
    MainButton,
    Map,
    UserCard,
    Card
  },
  methods: {
    setPageSize () {
      this.pageWidth = window.innerWidth
      this.pageHeight = window.innerHeight
    },
    openInMap () {
      window.open(`https://maps.apple.com/?saddr=${this.position.lat},${this.position.lng}&daddr=${this.activeCall.client.position.lat},${this.activeCall.client.position.lng}&dirflg=d`)
    }
  },
  computed: {
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift'
    }),
    ...createMapper(Call).mapGetters({
      activeCall: 'active'
    }),
    ...createMapper(Location).mapGetters({
      position: 'position',
      singlePatrolAreas: 'singlePatrolAreas',
      unionPatrolAreas: 'unionPatrolAreas'
    }),
    ...createMapper(Config).mapGetters({
      showMap: 'showMap',
      showSquad: 'showSquad'
    }),
    defaultClientImage () {
      return process.env.VUE_APP_DEFAULT_CLIENT_IMAGE_URL
    },
    defaultGuardImage () {
      return process.env.VUE_APP_DEFAULT_GUARD_IMAGE_URL
    },
    showInfo (): boolean {
      return this.showSquad || !!this.activeCall
    },
    markers () {
      const markers: {squad: object, call?: object} = {
        squad: {
          position: this.position,
          icon: '/img/icons/map/squad.svg'
        }
      }

      if (this.activeCall) {
        markers.call = {
          position: this.activeCall.client.position,
          icon: {
            url: '/img/icons/map/client.svg',
            anchor: {
              x: 48,
              y: 48
            }
          }
        }
      }

      return markers
    },
    polygons () {
      if (!this.unionPatrolAreas) {
        return null
      }

      const polygons: google.maps.PolygonOptions[] = []

      this.unionPatrolAreas.forEach((polygon: GMapPoint[][]) => {
        polygons.push({
          paths: polygon,
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#5c91a1',
          fillOpacity: 0.1
        })
      })

      this.singlePatrolAreas.forEach((patrolArea: PatrolAreaType) => {
        polygons.push({
          paths: patrolArea.points,
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#31c5c5',
          fillOpacity: 0.05
        })
      })
      return polygons
    },
    direction () {
      if (!this.activeCall) {
        return null
      }

      return {
        from: this.position,
        to: this.activeCall.client.position
      }
    },
    mapCardHeight () {
      let mapCardHeight = this.pageHeight - 55

      if (this.pageWidth <= 768 && this.showInfo) {
        mapCardHeight -= 10

        if (this.activeCall) {
          mapCardHeight -= (document.querySelector('.active-call')?.clientHeight || 0) + 20
        }

        if (this.showSquad) {
          mapCardHeight -= (121 + this.workShift.guards.length * 62)
        }
      }

      return `${mapCardHeight}px`
    }
  },
  created () {
    this.setPageSize()
    window.addEventListener('resize', this.setPageSize)
  }
})

export default class Main extends TranslatableClassComponent {
  translationGroup = 'squad_main_screen'
}
