import { Actions, Getters, Module, Mutations } from 'vuex-smart-module'
import { Store } from 'vuex'

import router from '@/router'

import configuredAxios from '@/libraries/axiosClient'

import {
  WORK_SHIFT_LOCAL_STORAGE_KEY,
  LAST_PAUSE_LOCAL_STORAGE_KEY,
  REPORTS_SCHEDULE_LOCAL_STORAGE_KEY
} from '@/constants'

import { WorkShiftResponse } from '@/types/api/responses/workShift/WorkShiftResponse'
import { WorkShiftType } from '@/types/entities/WorkShiftType'
import { CreateWorkShiftRequest } from '@/types/api/requests/workShift/CreateWorkShiftRequest'
import { PauseWorkShiftRequest } from '@/types/api/requests/workShift/PauseWorkShiftRequest'
import { DefaultResponse } from '@/types/api/responses/DefaultResponse'
import { ReportScheduleItem } from '@/types/libraries/ReportScheduleItem'
import { Status } from '@/types/api/responses/workShift/Status'

class WorkShiftState {
  workShift?: WorkShiftType
  callsReportsSchedule?: Record<number, ReportScheduleItem>

  constructor () {
    const reportsSchedule = localStorage.getItem(REPORTS_SCHEDULE_LOCAL_STORAGE_KEY)
    this.callsReportsSchedule = reportsSchedule ? JSON.parse(reportsSchedule) : undefined
  }
}

class WorkShiftGetters extends Getters<WorkShiftState> {
  get workShift () {
    return this.state.workShift
  }

  get callsReportsSchedule () {
    return this.state.callsReportsSchedule
  }
}

class WorkShiftMutations extends Mutations<WorkShiftState> {
  setWorkShift (workShift: WorkShiftType) {
    this.state.workShift = workShift
    localStorage.setItem(WORK_SHIFT_LOCAL_STORAGE_KEY, 'exist')
    localStorage.setItem(LAST_PAUSE_LOCAL_STORAGE_KEY, JSON.stringify(workShift.last_pause))
  }

  changeStatus (status: Status) {
    if (this.state.workShift) {
      this.state.workShift.status = status
    }
  }

  remove () {
    this.state.workShift = undefined
    localStorage.removeItem(WORK_SHIFT_LOCAL_STORAGE_KEY)
    localStorage.removeItem(LAST_PAUSE_LOCAL_STORAGE_KEY)
    localStorage.removeItem(REPORTS_SCHEDULE_LOCAL_STORAGE_KEY)
    router.push('/work-shift')
  }

  setCallsReportsSchedule (callsReportsSchedule?: Record<number, ReportScheduleItem>) {
    this.state.callsReportsSchedule = callsReportsSchedule
    localStorage.setItem(REPORTS_SCHEDULE_LOCAL_STORAGE_KEY, JSON.stringify(callsReportsSchedule))
  }
}

class WorkShiftActions extends Actions<
  WorkShiftState,
  WorkShiftGetters,
  WorkShiftMutations,
  WorkShiftActions
  > {
  protected store!: Store<any>

  $init (store: Store<any>) {
    super.$init(store)
    this.store = store
  }

  create (workShiftRequest: CreateWorkShiftRequest) {
    return new Promise((resolve, reject) => {
      configuredAxios.post('/work-shifts', workShiftRequest).then((response: {data: WorkShiftResponse}) => {
        this.mutations.setWorkShift(response.data.data)
        this.store.commit('Call/setActive', response.data.data.active_call)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  getCurrent () {
    return new Promise((resolve, reject) => {
      configuredAxios.get('/work-shifts').then((response: {data: WorkShiftResponse}) => {
        this.mutations.setWorkShift(response.data.data)
        this.store.commit('Call/setActive', response.data.data.active_call)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        this.store.commit('Call/setActive', null)
        reject(error)
      })
    })
  }

  finish () {
    return new Promise((resolve, reject) => {
      configuredAxios.delete('/work-shifts').then((response: {data: WorkShiftResponse}) => {
        this.mutations.remove()
        this.store.commit('Call/removeCall')
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  pause (pauseWorkShiftRequest: PauseWorkShiftRequest) {
    return new Promise((resolve, reject) => {
      configuredAxios.post('/work-shifts/pause', pauseWorkShiftRequest).then((response: {data: WorkShiftResponse}) => {
        this.mutations.setWorkShift(response.data.data)
        this.store.commit('Call/setActive', response.data.data.active_call)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  extensionPause (pauseWorkShiftRequest: PauseWorkShiftRequest) {
    return new Promise((resolve, reject) => {
      configuredAxios.patch('/work-shifts/pause', pauseWorkShiftRequest).then((response: {data: WorkShiftResponse}) => {
        this.mutations.setWorkShift(response.data.data)
        this.store.commit('Call/setActive', response.data.data.active_call)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  resume () {
    return new Promise((resolve, reject) => {
      configuredAxios.delete('/work-shifts/pause').then((response: {data: WorkShiftResponse}) => {
        this.mutations.setWorkShift(response.data.data)
        this.store.commit('Call/setActive', response.data.data.active_call)
        resolve(response.data)
      }).catch((error: DefaultResponse) => {
        reject(error)
      })
    })
  }

  setCallsReportsSchedule (callsReportsSchedule?: Record<number, ReportScheduleItem>) {
    this.mutations.setCallsReportsSchedule(callsReportsSchedule)
  }
}

export default new Module({
  state: WorkShiftState,
  getters: WorkShiftGetters,
  mutations: WorkShiftMutations,
  actions: WorkShiftActions
})
