
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import router from '@/router'

import MainButton from '@/components/base/buttons/MainButton.vue'
import Card from '@/components/base/cards/Card.vue'
import BaseSelect from '@/components/base/selects/BaseSelect.vue'
import FormGroup from '@/components/base/form/FormGroup.vue'

import HttpErrorsHandler from '@/components/abstaract/HttpErrorsHandler'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

import { CarType } from '@/types/entities/CarType'
import { DefaultResponse } from '@/types/api/responses/DefaultResponse'
import { GuardType } from '@/types/entities/GuardType'
import { OfficeType } from '@/types/entities/OfficeType'
import { OfficeResponse } from '@/types/api/responses/office/OfficeResponse'
import { WorkShiftType } from '@/types/entities/WorkShiftType'

import Config from '@/store/Config'
import Location from '@/store/Location'
import Office from '@/store/Office'
import WorkShift from '@/store/WorkShift'

@Options({
  components: {
    FormGroup,
    BaseSelect,
    Card,
    MainButton
  },
  data () {
    return {
      isFetching: false,
      currentOfficeId: null,
      currentCarId: null,
      currentGuards: [],
      errors: {
        office: '',
        car: '',
        guards: ''
      }
    }
  },
  methods: {
    ...createMapper(Office).mapActions({
      getOffices: 'getOffices'
    }),
    ...createMapper(WorkShift).mapActions({
      create: 'create'
    }),
    ...createMapper(Location).mapActions({
      getLocation: 'getLocation'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    createWorkShift () {
      this.errors.office = this.currentOfficeId ? '' : this.translate('empty_office')
      this.errors.car = this.currentCarId ? '' : this.translate('empty_car')
      this.errors.guards = this.currentGuards.length ? '' : this.translate('empty_guards')

      if (!this.currentOfficeId || !this.currentCarId || !this.currentGuards.length) {
        return
      }

      this.setFetching(true)
      this.create({
        office_id: this.currentOfficeId,
        car_id: this.currentCarId,
        guards: this.currentGuards
      }).then(() => {
        this.setFetching(false)
        router.push('/')
      }).catch((error: DefaultResponse) => {
        this.httpErrorHandler(error)
        this.setFetching(false)
      })
    }
  },
  computed: {
    ...createMapper(Office).mapGetters({
      offices: 'offices'
    }),
    ...createMapper(Location).mapGetters({
      position: 'position'
    }),
    officesOptions () {
      return this.offices.map((office: OfficeType) => {
        return {
          label: office.name,
          value: office.id
        }
      })
    },
    currentOffice (): OfficeType {
      return this.offices.find((office: OfficeType) => office.id === this.currentOfficeId)
    },
    cars (): CarType[] {
      return this.currentOffice.cars
    },
    carsOptions () {
      return this.cars.map((car: CarType) => {
        return {
          label: car.tail_number,
          value: car.id
        }
      })
    },
    guards (): GuardType[] {
      return this.currentOffice.guards
    },
    guardsOptions () {
      return this.guards.map((guard: GuardType) => {
        return {
          label: guard.name,
          value: guard.id,
          image: guard.avatar
        }
      })
    }
  },
  created () {
    if (this.workShift) {
      router.push('/')
      return
    }

    this.isFetching = true
    this.getOffices().then((officeResponse: OfficeResponse) => {
      this.isFetching = false
      if (officeResponse.data.length) {
        this.currentOfficeId = officeResponse.data[0].id
      }
    }).catch(() => {
      this.isFetching = false
    })

    this.fieldsHandlersForValidationHandler = {
      guards: (translatedError: string): void => {
        this.errors.guards = (`${this.errors.guards} ${translatedError}`).trim()
      },
      car_id: (translatedError: string): void => {
        this.errors.car = (`${this.errors.car} ${translatedError}`).trim()
      },
      office_id: (translatedError: string): void => {
        this.errors.office = (`${this.errors.office} ${translatedError}`).trim()
      }
    }

    this.getLocation()
  },
  watch: {
    workShift (workShift?: WorkShiftType) {
      if (workShift) {
        router.push('/')
      }
    }
  }
})

export default class CreateWorkShift extends mixins(TranslatableClassComponent, HttpErrorsHandler) {
  translationGroup = 'squad_work_shift_screen'
}
