
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'

import router from '@/router'
import soundNotificator from '@/libraries/soundNotificator'
import observer from '@/libraries/observer'

import { EARTH_RADIUS, WORK_SHIFT_PAUSE_OBSERVER_NAME } from '@/constants'

import { Status as WorkShiftStatus } from '@/types/api/responses/workShift/Status'
import { WorkShiftType } from '@/types/entities/WorkShiftType'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import Badge from '@/components/base/decorations/Badge.vue'
import EmptyButton from '@/components/base/buttons/EmptyButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import PauseControls from '@/components/layouts/PauseControls.vue'

import CallConfirmationModal from '@/components/custom/modals/CallConfirmationModal.vue'

import Auth from '@/store/Auth'
import Call from '@/store/Call'
import Config from '@/store/Config'
import Location from '@/store/Location'
import MainPageControls from '@/components/layouts/MainPageControls.vue'
import WorkShift from '@/store/WorkShift'

@Options({
  data () {
    return {
      pauseModal: false,
      extensionPauseModal: false,
      pauseReason: '',
      pauseTimeRange: 5,
      pauseTimeLeft: 0,
      pauseTimeLimit: 0,
      isMobile: false,
      showMobileActions: false
    }
  },
  components: {
    MainPageControls,
    PauseControls,
    Badge,
    EmptyButton,
    MainButton
  },
  created () {
    this.setIsMobile()
    window.addEventListener('resize', this.setIsMobile)

    if (this.workShift?.status === 'paused' && this.workShift?.last_pause?.remained_time) {
      observer.subscribe(this.setPauseTimer, WORK_SHIFT_PAUSE_OBSERVER_NAME)
      this.pauseTimeLeft = this.workShift.last_pause.remained_time
      this.pauseTimeLimit = this.pauseTimeLeft + Number((new Date().getTime() / 1000).toFixed())
    }
  },
  computed: {
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift'
    }),
    ...createMapper(Location).mapGetters({
      areas: 'areas',
      inArea: 'inArea',
      position: 'position'
    }),
    ...createMapper(Call).mapGetters({
      activeCall: 'active'
    }),
    ...createMapper(Config).mapGetters({
      maxDistanceToClientForArriving: 'maxDistanceToClientForArriving'
    }),
    timer () {
      return Math.floor(this.pauseTimeLeft / 60) + ':' + ('0' + Math.floor(this.pauseTimeLeft % 60)).slice(-2)
    },
    nextCallStatus (): string {
      switch (this.activeCall.status) {
        case 'squad_in_road':
          return 'squad_arrived'
        case 'squad_arrived':
          return 'finished'
        default:
          return this.activeCall.status
      }
    },
    showNextCallStatusButton (): boolean {
      if (!this.activeCall) {
        return false
      }

      if (this.nextCallStatus !== 'squad_arrived') {
        return true
      }

      if (!this.position) {
        return false
      }

      const x = Math.PI / 180

      const lat1 = this.activeCall.client.position.lat * x
      const lng1 = this.activeCall.client.position.lng * x
      const lat2 = this.position.lat * x
      const lng2 = this.position.lng * x

      const distance = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin((lat1 - lat2) / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin((lng1 - lng2) / 2), 2)))

      return this.maxDistanceToClientForArriving > distance * EARTH_RADIUS
    },
    areasLabel (): string {
      const areasList = this.areas.join(', ')

      if (this.isMobile) {
        return areasList
      }

      return this.translateWithVariables('in_zone', { area: areasList })
    },
    canFinishWorkShift (): boolean {
      return this.workShift && ['available', 'paused'].includes(this.workShift.status) && !this.workShift.not_reported_calls.length && !this.workShift.not_approved_report_calls.length
    },
    safari (): boolean {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
    isRouteMain (): boolean {
      return this.$route.name === 'Main'
    }
  },
  methods: {
    ...createMapper(WorkShift).mapActions({
      finish: 'finish'
    }),
    ...createMapper(Auth).mapActions({
      logout: 'logout'
    }),
    ...createMapper(Call).mapActions({
      changeStatus: 'changeStatus'
    }),
    ...createMapper(Call).mapMutations({
      setIsActiveCallUnconfirmed: 'setIsActiveUnconfirmed'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    finishWorkShiftButtonHandler (): void {
      this.swal({
        title: this.translate('finish_work_shift_modal.title'),
        text: this.translate('finish_work_shift_modal.description'),
        showCancelButton: true
      }).then((result: SweetAlertResult) => {
        if (result.isDismissed) {
          return
        }

        this.setFetching(true)
        this.finish().then(() => {
          this.setFetching(false)
          router.push('/work-shift')
        }).catch(() => {
          this.setFetching(false)
        })
      })
    },
    logoutButtonHandler (): void {
      const swalOptions: SweetAlertOptions = {
        title: this.translate('log_out_modal.title'),
        showCancelButton: true
      }

      if (this.canFinishWorkShift) {
        swalOptions.title = this.translate('log_out_with_finish_work_shift_modal.title')
        swalOptions.text = this.translate('log_out_with_finish_work_shift_modal.description')
        swalOptions.confirmButtonText = this.translate('log_out_with_finish_work_shift_modal.confirm')
        swalOptions.denyButtonText = this.translate('log_out_with_finish_work_shift_modal.deny')
        swalOptions.showDenyButton = true
      }

      this.swal(swalOptions).then((result: SweetAlertResult) => {
        if (result.isDismissed) {
          return
        }

        this.setFetching(true)
        this.logout(this.canFinishWorkShift && result.value).then(() => {
          this.setFetching(false)
          router.push('/auth')
        }).catch(() => {
          this.setFetching(false)
        })
      })
    },
    setPauseTimer (time: number) {
      const pauseTimeLeft = this.pauseTimeLimit - time
      if (pauseTimeLeft <= 0) {
        this.pauseTimeLeft = 0
        observer.unsubscribe(WORK_SHIFT_PAUSE_OBSERVER_NAME)
        return
      }

      this.pauseTimeLeft = pauseTimeLeft
    },
    changeCallToNextStatus () {
      this.swal({
        title: this.translate(`confirm_change_call_status_to_${this.nextCallStatus}`),
        showCancelButton: true
      }).then((result: SweetAlertResult) => {
        if (result.isDismissed) {
          return
        }

        this.setFetching(true)
        this.changeStatus(this.nextCallStatus).then(() => {
          this.setFetching(false)
        }).catch(() => {
          this.setFetching(false)
        })
      })
    },
    goToCalls () {
      router.push('/calls')
    },
    goToMainIfNeeded () {
      if (this.isRouteMain) {
        return
      }

      router.push('/')
    },
    reload () {
      location.reload()
    },
    setIsMobile () {
      this.isMobile = window.innerWidth <= 425
      this.showMobileActions = false
    }
  },
  watch: {
    workShift (workShift: WorkShiftType, prevWorkShift: WorkShiftType) {
      if (!workShift) {
        return
      }

      if (workShift?.active_call && !prevWorkShift?.active_call) {
        this.setIsActiveCallUnconfirmed(true)
      }

      if (workShift.status !== 'paused' as WorkShiftStatus) {
        if (prevWorkShift && prevWorkShift.status === 'paused' as WorkShiftStatus) {
          this.pauseTimeLeft = 0
          observer.unsubscribe(WORK_SHIFT_PAUSE_OBSERVER_NAME)
          this.notifyAlert(this.translate('pause_finished'), '', 'info')
        }

        return
      }

      if (typeof workShift.last_pause !== 'undefined' && workShift.last_pause) {
        this.pauseTimeLeft = workShift.last_pause.remained_time
        this.pauseTimeLimit = this.pauseTimeLeft + Number((new Date().getTime() / 1000).toFixed())
      }

      if (prevWorkShift.status !== 'paused' as WorkShiftStatus) {
        observer.subscribe(this.setPauseTimer, WORK_SHIFT_PAUSE_OBSERVER_NAME)
        soundNotificator.notify()
      }
    },
    $route () {
      this.showMobileActions = false
    }
  }
})

export default class Header extends mixins(TranslatableClassComponent, TranslatableSwal) {
  translationGroup = 'squad_base'
}
