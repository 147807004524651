
import { Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

import Call from '@/store/Call'
import WorkShift from '@/store/WorkShift'

import { ReportScheduleItem } from '@/types/libraries/ReportScheduleItem'
import { WorkShiftType } from '@/types/entities/WorkShiftType'

import Badge from '@/components/base/decorations/Badge.vue'
import Card from '@/components/base/cards/Card.vue'
import Config from '@/store/Config'
import MainButton from '@/components/base/buttons/MainButton.vue'

@Options({
  data () {
    return {
    }
  },
  components: {
    Badge,
    MainButton,
    Card
  },
  methods: {
    ...createMapper(Call).mapActions({
      getList: 'getList'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    ...createMapper(WorkShift).mapActions({
      setCallsReportsSchedule: 'setCallsReportsSchedule'
    }),
    writeReport (callId: number) {
      const callsReportsSchedule: Record<number, ReportScheduleItem> = this.callsReportsSchedule ? { ...this.callsReportsSchedule } : {}
      callsReportsSchedule[callId] = {
        withoutSound: true,
        nextShowTime: 0,
        ignoreActiveCall: true
      }
      this.setCallsReportsSchedule(callsReportsSchedule)
    },
    changeReport (callId: number) {
      const callsReportsSchedule: Record<number, ReportScheduleItem> = this.callsReportsSchedule ? { ...this.callsReportsSchedule } : {}
      callsReportsSchedule[callId] = {
        withoutSound: true,
        needEdit: true,
        nextShowTime: 0,
        ignoreActiveCall: true
      }
      this.setCallsReportsSchedule(callsReportsSchedule)
    }
  },
  computed: {
    ...createMapper(Call).mapGetters({
      list: 'list'
    }),
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift',
      callsReportsSchedule: 'callsReportsSchedule'
    }),
    defaultClientImage () {
      return process.env.VUE_APP_DEFAULT_CLIENT_IMAGE_URL
    }
  },
  created () {
    this.setFetching(true)
    this.getList({}).then(() => {
      this.setFetching(false)
    }).catch(() => {
      this.setFetching(false)
    })
  },
  watch: {
    workShift (workShift?: WorkShiftType, prevWorkShift?: WorkShiftType) {
      if (!workShift) {
        return
      }

      if (
        !prevWorkShift ||
        JSON.stringify(prevWorkShift.not_approved_report_calls) !== JSON.stringify(workShift.not_approved_report_calls) ||
        JSON.stringify(prevWorkShift.not_reported_calls) !== JSON.stringify(workShift.not_reported_calls)
      ) {
        this.setFetching(true)
        this.getList({}).then(() => {
          this.setFetching(false)
        }).catch(() => {
          this.setFetching(false)
        })
      }
    }
  }
})

export default class Calls extends TranslatableClassComponent {
  translationGroup = 'squad_calls_screen'
}
