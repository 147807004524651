import axios from 'axios'
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from '@/constants'

const configuredAxios = axios

configuredAxios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
configuredAxios.defaults.headers.common.Accept = 'application/json'
configuredAxios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY)

export default configuredAxios
