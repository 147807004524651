import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-main-wrapper" }
const _hoisted_2 = { class: "app-version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PwaBanner = _resolveComponent("PwaBanner")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LocationProvider = _resolveComponent("LocationProvider")!
  const _component_CallProvider = _resolveComponent("CallProvider")!
  const _component_SoundNotificatorsProvider = _resolveComponent("SoundNotificatorsProvider")!
  const _component_WorkShiftProvider = _resolveComponent("WorkShiftProvider")!
  const _component_ConfigProvider = _resolveComponent("ConfigProvider")!
  const _component_UpdatingProvider = _resolveComponent("UpdatingProvider")!
  const _component_TranslationsProvider = _resolveComponent("TranslationsProvider")!
  const _component_ConnectionProvider = _resolveComponent("ConnectionProvider")!
  const _component_FetchingProvider = _resolveComponent("FetchingProvider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FetchingProvider, null, {
      default: _withCtx(() => [
        _createVNode(_component_ConnectionProvider, null, {
          default: _withCtx(() => [
            _createVNode(_component_TranslationsProvider, null, {
              default: _withCtx(() => [
                _createVNode(_component_UpdatingProvider, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ConfigProvider, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_WorkShiftProvider, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_SoundNotificatorsProvider, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_CallProvider, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_LocationProvider, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_PwaBanner),
                                        _createElementVNode("div", {
                                          class: _normalizeClass(`${_ctx.isMacosOrIos ? 'mac' : ''} main-wrapper`)
                                        }, [
                                          (_ctx.showHeader)
                                            ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
                                            : _createCommentVNode("", true),
                                          _createElementVNode("main", {
                                            class: _normalizeClass(`${_ctx.showHeader ? 'with-header' : ''}`)
                                          }, [
                                            _createVNode(_component_router_view)
                                          ], 2)
                                        ], 2)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.env.VERSION), 1)
  ]))
}