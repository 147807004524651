
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import MainButton from '@/components/base/buttons/MainButton.vue'
import Modal from '@/components/base/modal/Modal.vue'
import Card from '@/components/base/cards/Card.vue'

import ConfigState from '@/store/Config'

@Options({
  components: {
    MainButton,
    Modal,
    Card
  },
  data () {
    return {
      serviceWorkerRegistration: null,
      updating: false,
      updatingType: '',
      updatingInterval: 0
    }
  },
  methods: {
    ...createMapper(ConfigState).mapMutations({
      setIsNewVersionAvailable: 'setIsNewVersionAvailable'
    }),
    updateAvailable (event: CustomEvent) {
      clearInterval(this.updatingInterval)
      this.serviceWorkerRegistration = event.detail
      this.setIsNewVersionAvailable(true)
      this.updatingType = 'need_skip_waiting'
    },
    refreshApp () {
      this.setIsNewVersionAvailable(false)

      if (this.updatingType === 'reload') {
        window.location.reload()
        return
      } else {
        if (!this.serviceWorkerRegistration || !this.serviceWorkerRegistration.waiting) {
          window.location.reload()
          return
        }

        this.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }

      this.updatingType = ''
    }
  },
  computed: {
    ...createMapper(ConfigState).mapGetters({
      isNewVersionAvailable: 'isNewVersionAvailable'
    })
  },
  created () {
    document.addEventListener('serviceWorkerUpdated', this.updateAvailable, { once: true })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.updating) {
        return
      }
      this.updating = true

      window.location.reload()
    })

    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      this.updatingInterval = setInterval(
        () => {
          registration.update()
        },
        process.env.VUE_APP_CHECK_UPDATES_INTERVAL_SECONDS ? (process.env.VUE_APP_CHECK_UPDATES_INTERVAL_SECONDS * 1000) : 600000
      )

      registration.addEventListener('updatefound', () => {
        if (this.isNewVersionAvailable) {
          return
        }

        this.setIsNewVersionAvailable(true)
        this.updatingType = 'reload'
      })
    })
  }
})

export default class SoundNotificatorsProvider extends mixins(TranslatableClassComponent) {
  translationGroup = 'squad_base'
}
