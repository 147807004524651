
import { Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import observer from '@/libraries/observer'
import { LOCATION_OBSERVER_NAME } from '@/constants'

import { Position } from '@/types/libraries/Position'
import { WorkShiftType } from '@/types/entities/WorkShiftType'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

import Card from '@/components/base/cards/Card.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

import Auth from '@/store/Auth'
import Config from '@/store/Config'
import Location from '@/store/Location'
import WorkShift from '@/store/WorkShift'

@Options({
  data () {
    return {
      gotPatrolAreas: false,
      positionWatcher: undefined
    }
  },
  components: {
    MainButton,
    Card
  },
  methods: {
    ...createMapper(Location).mapActions({
      getLocation: 'getLocation',
      getPatrolAreas: 'getPatrolAreas',
      setLocation: 'setLocation'
    }),
    ...createMapper(Location).mapMutations({
      setNeedActiveLocationPermission: 'setNeedActiveLocationPermission'
    }),
    subscribeToLocation () {
      observer.subscribe(this.setLocationIfNeeded, LOCATION_OBSERVER_NAME, this.updateCoordinatesPeriod)
      this.positionWatcher = navigator.geolocation.watchPosition(this.updateLocation, (error) => {
        if (error.code === 1) {
          this.setNeedActiveLocationPermission(true)
        }
      })
    },
    unsubscribeFromLocation () {
      observer.unsubscribe(LOCATION_OBSERVER_NAME)
      navigator.geolocation.clearWatch(this.positionWatcher)
    }
  },
  computed: {
    ...createMapper(Auth).mapGetters({
      isAuth: 'isAuth'
    }),
    ...createMapper(Location).mapGetters({
      needActiveLocationPermission: 'needActiveLocationPermission'
    }),
    ...createMapper(Config).mapGetters({
      updateCoordinatesPeriod: 'updateCoordinatesPeriod',
      showMap: 'showMap'
    }),
    ...createMapper(WorkShift).mapGetters({
      workShift: 'workShift'
    })
  },
  created () {
    if (!this.workShift) {
      return
    }

    this.subscribeToLocation()

    if (this.showMap) {
      this.getPatrolAreas()
      this.gotPatrolAreas = true
    }
  },
  watch: {
    workShift (nextWorkShift?: WorkShiftType, prevWorkShift?: WorkShiftType) {
      if (nextWorkShift && !prevWorkShift) {
        this.subscribeToLocation()

        if (this.showMap) {
          this.getPatrolAreas()
          this.gotPatrolAreas = true
        }
      }

      if (!nextWorkShift && prevWorkShift) {
        this.unsubscribeFromLocation()
      }
    },
    showMap (showMap: boolean) {
      if (showMap && !this.gotPatrolAreas) {
        this.getPatrolAreas()
        this.gotPatrolAreas = true
      }
    }
  }
})

export default class ConfigProvider extends TranslatableClassComponent {
  translationGroup = 'squad_location_detect_error_screen';

  currentPosition?: Position;
  lastSentPosition?: Position;

  setLocation!: (position: Position) => Promise<Position>;

  updateLocation (geoLocationPosition: GeolocationPosition) {
    this.currentPosition = {
      lat: geoLocationPosition.coords.latitude,
      lng: geoLocationPosition.coords.longitude,
      accuracy: geoLocationPosition.coords.accuracy
    }
  }

  setLocationIfNeeded () {
    const position = this.currentPosition
    if (!position || JSON.stringify(position) === JSON.stringify(this.lastSentPosition)) {
      return
    }

    this.setLocation(position).then(() => {
      this.lastSentPosition = position
    })
  }
}
