import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = { class: "modal-title" }
const _hoisted_4 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyButton = _resolveComponent("EmptyButton")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      classes: `modal ${_ctx.classes}`
    }, {
      default: _withCtx(() => [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
          (_ctx.onClose)
            ? (_openBlock(), _createBlock(_component_EmptyButton, {
                key: 0,
                classes: "modal-close",
                onClick: _ctx.onClose,
                text: "❌"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 8, ["classes"])
  ]))
}