
import { Vue, Options } from 'vue-class-component'
import router from '@/router'

import CallProvider from '@/components/providers/CallProvider.vue'
import ConnectionProvider from '@/components/providers/ConnectionProvider.vue'
import ConfigProvider from '@/components/providers/ConfigProvider.vue'
import FetchingProvider from '@/components/providers/FetchingProvider.vue'
import LocationProvider from '@/components/providers/LocationProvider.vue'
import TranslationsProvider from '@/components/providers/TranslationsProvider.vue'
import WorkShiftProvider from '@/components/providers/WorkShiftProvider.vue'
import SoundNotificatorsProvider from '@/components/providers/SoundNotificatorsProvider.vue'
import UpdatingProvider from '@/components/providers/UpdatingProvider.vue'

import Header from '@/components/layouts/Header.vue'
import PwaBanner from '@/components/layouts/PwaBanner.vue'

@Options({
  components: {
    PwaBanner,
    ConnectionProvider,
    CallProvider,
    ConfigProvider,
    FetchingProvider,
    LocationProvider,
    Header,
    WorkShiftProvider,
    TranslationsProvider,
    SoundNotificatorsProvider,
    UpdatingProvider
  },
  computed: {
    showHeader (): boolean {
      const routeMeta = router.currentRoute.value.meta
      return (typeof routeMeta.auth === 'undefined' || routeMeta.auth !== false)
    },
    isMacosOrIos (): boolean {
      const platform = navigator.platform.toLowerCase()
      const iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch']

      return platform.includes('mac') || iosPlatforms.includes(platform)
    },
    env () {
      return process.env
    }
  }
})

export default class App extends Vue {}
