import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-header"
}
const _hoisted_2 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card ${_ctx.classes || ''}`)
  }, [
    (_ctx.$slots.title && _ctx.$slots.title()[0].children)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", null, [
            _renderSlot(_ctx.$slots, "title")
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}