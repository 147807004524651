
import { mixins, Options } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'
import TranslatableSwal from '@/components/abstaract/TranslatableSwal'

import DenyButton from '@/components/base/buttons/DenyButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import Modal from '@/components/base/modal/Modal.vue'
import TextareaField from '@/components/base/inputs/TextareaField.vue'

import Call from '@/store/Call'
import Config from '@/store/Config'
import WorkShift from '@/store/WorkShift'

@Options({
  components: {
    DenyButton,
    MainButton,
    TextareaField,
    Modal
  },
  props: {
    title: String,
    onClose: Function,
    currentCall: Object
  },
  data () {
    return {
      report: '',
      reportError: '',
      defaultImageUrl: process.env.VUE_APP_DEFAULT_CLIENT_IMAGE_URL
    }
  },
  methods: {
    ...createMapper(Call).mapActions({
      sendReport: 'report'
    }),
    ...createMapper(WorkShift).mapActions({
      setCallsReportsSchedule: 'setCallsReportsSchedule'
    }),
    ...createMapper(Config).mapMutations({
      setFetching: 'setFetching'
    }),
    handleSubmit () {
      this.setFetching(true)
      this.sendReport({
        callId: this.currentCall.id,
        text: this.report
      }).then(() => {
        this.swal({
          icon: 'success',
          title: this.translate('report_sent')
        })
        this.onClose()
        this.setFetching(false)
      }).catch(() => {
        this.setFetching(false)
      })
    },
    handleCloseClick () {
      const callsReportsSchedule = this.callsReportsSchedule ? { ...this.callsReportsSchedule } : {}
      callsReportsSchedule[this.currentCall.id] = {
        nextShowTime: Math.round(new Date().getTime() / 1000) + this.remindCallReportPeriod
      }
      this.setCallsReportsSchedule(callsReportsSchedule)
      this.onClose()
    }
  },
  computed: {
    ...createMapper(WorkShift).mapGetters({
      callsReportsSchedule: 'callsReportsSchedule'
    }),
    ...createMapper(Config).mapGetters({
      remindCallReportPeriod: 'remindCallReportPeriod'
    }),
    timeRange () {
      return `📅 ${new Date(this.currentCall.created_at.replaceAll('-', '/')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} — ${new Date(this.currentCall.finished_at.replaceAll('-', '/')).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
    }
  },
  created () {
    this.report = this.currentCall?.report?.details.text || ''
  }
})

export default class WorkShiftProvider extends mixins(TranslatableClassComponent, TranslatableSwal) {
  translationGroup = 'squad_base'
}
