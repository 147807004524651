import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "work-shift"
}
const _hoisted_2 = { class: "work-shift-form" }
const _hoisted_3 = {
  key: 1,
  class: "need-position-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_Card = _resolveComponent("Card")!

  return (typeof _ctx.offices !== 'undefined' && !_ctx.isFetching)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Card, null, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate('work_shift')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.officesOptions.length > 1)
                ? (_openBlock(), _createBlock(_component_FormGroup, {
                    key: 0,
                    label: _ctx.translate('office'),
                    "label-for": "office-select",
                    error: _ctx.errors.office
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BaseSelect, {
                        value: _ctx.currentOfficeId,
                        id: "office-select",
                        searchable: true,
                        options: _ctx.officesOptions,
                        onChange: (newCurrentOfficeId) => _ctx.currentOfficeId = newCurrentOfficeId
                      }, null, 8, ["value", "options", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label", "error"]))
                : _createCommentVNode("", true),
              _createVNode(_component_FormGroup, {
                label: _ctx.translate('car'),
                "label-for": "car-select",
                error: _ctx.errors.car
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseSelect, {
                    value: _ctx.currentCarId,
                    id: "car-select",
                    searchable: true,
                    options: _ctx.carsOptions,
                    onChange: (newCurrentCarId) => _ctx.currentCarId = newCurrentCarId
                  }, null, 8, ["value", "options", "onChange"])
                ]),
                _: 1
              }, 8, ["label", "error"]),
              _createVNode(_component_FormGroup, {
                label: _ctx.translate('guards'),
                "label-for": "guards-select",
                error: _ctx.errors.guards
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseSelect, {
                    value: _ctx.currentGuards,
                    id: "guards-select",
                    searchable: true,
                    multiple: true,
                    options: _ctx.guardsOptions,
                    onChange: (newCurrentGuards) => _ctx.currentGuards = newCurrentGuards
                  }, null, 8, ["value", "options", "onChange"])
                ]),
                _: 1
              }, 8, ["label", "error"]),
              _createVNode(_component_MainButton, {
                text: _ctx.translate('start', 'squad_base'),
                classes: "work-shift-submit",
                onClick: _ctx.createWorkShift,
                disabled: !_ctx.position
              }, null, 8, ["text", "onClick", "disabled"]),
              (!_ctx.position)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.translate('need_set_position')), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}