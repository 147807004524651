import { Audio as AudioNotificator } from '@/types/libraries/Audio'
import { SoundNotificators } from '@/types/libraries/SoundNotificators'

class SoundNotificator {
  private notificators: SoundNotificators;
  private loaded!: boolean;

  constructor () {
    this.loaded = false

    this.notificators = {
      notifyAudio: {
        source: process.env.VUE_APP_NOTIFICATION_SOUND_URL ? process.env.VUE_APP_NOTIFICATION_SOUND_URL : '/sound/notification.wav',
        audio: undefined,
        loaded: false,
        repeat: false
      },
      callNotifyAudio: {
        source: process.env.VUE_APP_CALL_SOUND_URL ? process.env.VUE_APP_CALL_SOUND_URL : '/sound/call.mp3',
        audio: undefined,
        loaded: false,
        repeat: false
      }
    }
  }

  isLoaded () {
    return this.loaded
  }

  load () {
    return new Promise((resolve, reject) => {
      const promisesForSoundLoading: Promise<boolean>[] = this.getArrayOfPromisesForSoundLoading()
      if (!promisesForSoundLoading.length) {
        resolve(true)
        return
      }

      Promise.all(promisesForSoundLoading).then(() => {
        this.loaded = true
        resolve(true)
      }, reason => {
        this.loaded = false
        reject(reason)
      })
    })
  }

  getArrayOfPromisesForSoundLoading () {
    const promises: Promise<boolean>[] = []

    for (const notificator of Object.keys(this.notificators)) {
      if (this.notificators[notificator].loaded) {
        continue
      }

      promises.push(
        new Promise((resolve, reject) => {
          this.loadAudio(notificator, resolve, reject)
        })
      )
    }

    return promises
  }

  notify () {
    this.playNotificator('notifyAudio')
  }

  call (repeat: boolean = false) {
    this.playNotificator('callNotifyAudio', repeat)
  }

  playNotificator (notificatorName: string, repeat: boolean = false) {
    const notificator: AudioNotificator = this.notificators[notificatorName]

    if (!notificator || !notificator.audio) {
      return
    }

    notificator.repeat = repeat
    notificator.audio.play()
  }

  stopCall () {
    this.stopNotificator('callNotifyAudio')
  }

  stopNotificator (notificatorName: string) {
    const notificator: AudioNotificator = this.notificators[notificatorName]

    if (!notificator) {
      return
    }

    notificator.repeat = false

    if (!notificator.audio) {
      return
    }

    notificator.audio.pause()
    notificator.audio.currentTime = 0
  }

  loadAudio (notificatorName: string, resolve: any, reject: any) {
    const notificator: AudioNotificator = this.notificators[notificatorName]
    const notificatorAudio: HTMLAudioElement = new Audio(notificator.source)

    notificatorAudio.addEventListener('canplaythrough', () => {
      if (notificator.loaded) {
        return
      }

      notificator.audio = notificatorAudio
      notificator.loaded = true

      resolve(true)
    })

    notificatorAudio.addEventListener('ended', () => {
      if (notificator.repeat) {
        notificatorAudio.currentTime = 0
        notificatorAudio.play()
      }
    })

    notificatorAudio.addEventListener('error', () => {
      reject(`error while loading - ${notificatorName}`)
    })

    notificatorAudio.load()
  }
}

const soundNotificator = new SoundNotificator()
export default soundNotificator
