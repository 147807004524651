import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { store } from '@/store'
import { WORK_SHIFT_LOCAL_STORAGE_KEY } from '@/constants'

import Authorization from '@/views/Authorization.vue'
import Calls from '@/views/Calls.vue'
import Main from '@/views/Main.vue'
import WorkShift from '@/views/CreateWorkShift.vue'

enum Routes {
  auth = '/auth',
  create_work_shift = '/work-shift',
  main = '/',
  calls = '/calls',
}

const routes: Array<RouteRecordRaw> = [
  {
    path: Routes.auth,
    name: 'Authorization',
    component: Authorization,
    meta: {
      auth: false
    }
  },
  {
    path: Routes.create_work_shift,
    name: 'Work shift',
    component: WorkShift,
    meta: {
      work_shift: false
    }
  },
  {
    path: Routes.main,
    name: 'Main',
    component: Main,
    meta: {
      work_shift: true,
      auth: true
    }
  },
  {
    path: Routes.calls,
    name: 'Calls',
    component: Calls,
    meta: {
      work_shift: true,
      auth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const getAvailableRoute = (isLogged: boolean, isWorkShift: boolean): Routes => {
  if (!isLogged) {
    return Routes.auth
  }

  if (!isWorkShift) {
    return Routes.create_work_shift
  }

  return Routes.main
}

router.beforeEach((to, from, next) => {
  const isLogged = store.getters['Auth/isAuth']
  const isWorkShift = !!localStorage.getItem(WORK_SHIFT_LOCAL_STORAGE_KEY)
  const isValidRoute = !!to.name
  if (
    !isValidRoute ||
    (typeof to.meta.work_shift !== 'undefined' && (to.meta.work_shift !== isWorkShift)) ||
    (typeof to.meta.auth !== 'undefined' && (to.meta.auth !== isLogged))
  ) {
    return next(getAvailableRoute(isLogged, isWorkShift))
  }

  next()
})

export default router
