import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmptyButton = _resolveComponent("EmptyButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!!_ctx.workShift)
      ? (_openBlock(), _createBlock(_component_EmptyButton, {
          key: 0,
          text: "🗺",
          classes: _ctx.showMap ? 'show-map' : 'hide-map',
          onClick: _ctx.showMapHandler
        }, null, 8, ["classes", "onClick"]))
      : _createCommentVNode("", true),
    (!!_ctx.workShift)
      ? (_openBlock(), _createBlock(_component_EmptyButton, {
          key: 1,
          text: "🚔",
          classes: _ctx.showSquad ? 'show-squad' : 'hide-squad',
          onClick: _ctx.showSquadHandler
        }, null, 8, ["classes", "onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}