
import { Options, Vue } from 'vue-class-component'
import { createMapper } from 'vuex-smart-module'

import Config from '@/store/Config'

@Options({
  computed: {
    ...createMapper(Config).mapGetters({
      isFetching: 'isFetching'
    })
  }
})

export default class FetchingProvider extends Vue {}
