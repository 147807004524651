
import { Options } from 'vue-class-component'
import NumberInput from '@/components/base/inputs/NumberInput.vue'
import TranslatableClassComponent from '@/components/abstaract/TranslatableClassComponent'

@Options({
  props: {
    onInput: Function,
    onEnterKeyup: Function,
    error: String,
    phone: String,
    label: String,
    disabled: Boolean
  },
  components: {
    NumberInput
  },
  data () {
    return {
      localError: ''
    }
  },
  computed: {
    errorMessage () {
      if (this.error) {
        return this.error
      }

      if (this.localError) {
        return this.localError
      }

      return ''
    }
  }
})

export default class PhoneInput extends TranslatableClassComponent {
  phone!: string;
  localError!: string;
  onInput!: (phone: string) => void;

  translationGroup = 'squad_validators'

  handleChange ($event: {target: {value: string}}) {
    if ($event.target.value.length !== 12) {
      this.localError = this.translate('phone_error')
      return
    }

    this.localError = ''
  }
}
